import React, { useEffect, useState } from 'react';

import OrderModal from '../Model/Order.Modal';
import { useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { helper } from '../Lib/helper';
const Section = () => {
    const [sessionList, setSessionList] = useState();
    const [otherSessionList, setOtherSessionList] = useState();
    const [serverTime, setServerTime] = useState(null);
    const [serverFormatedTime, setServerFormatedTime] = useState(null);
    const [activeTab, setActiveTab] = useState('personal'); // Default active tab
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem("user_data")));
    const params = useParams();
    useEffect(() => {
        OrderModal.userOrderSessionList(params.id).then((res) => {
            setSessionList(res?.data?.data?.personal_sessions);
            if (res?.data?.data?.other_sessions) {
                setOtherSessionList(res?.data?.data?.other_sessions);
            }
            if(res?.data?.data?.currentDateTime) {
                setServerTime(res?.data?.data?.currentDateTime);
                setServerFormatedTime(helper.convertDate(res?.data?.data?.currentDateTime))
            }
            console.log("Time", helper.convertDate(res?.data?.data?.currentDateTime))
        }).catch((error) => {
            console.log("error fetching order sessions");
        });

    }, [])
    const columns = [
        {
            name: ' S.No.',

            cell: (row, index) => index + 1,
            width: '80px',
        },

        {
            name: ' Session Name',
            selector: (row) => row.session_name,
        },
        {
            name: ' Batch Name',
            width: '250px',
            selector: (row) => row.batch_name,
        },
        {
            name: 'Status',
            cell: (row) => {
                return (
                    <Row>
                        <Col>
                            {helper.Capitalize(row.status)}
                        </Col>
                    </Row>
                );
            }
            //selector: (row) => row.status,
        },
        /* {
            name: ' Created On	',
            selector: (row) => helper.convertDate(row.created_at),
        }, */
        {
            name: 'Action	',
            cell: (row) => {
                const startTime = serverTime && !isNaN(Date.parse(serverTime)) ? new Date(serverTime) : new Date();
                const endTime = serverTime && !isNaN(Date.parse(serverTime)) ? new Date(serverTime) : new Date();
                const startSplit = row.starts_at.split(' ');
                const startHourMinute = startSplit[0].split(':');
                const isPMStart = startSplit[1].toLowerCase() === 'pm';
                let startHour = parseInt(startHourMinute[0], 10);
                if (isPMStart && startHour !== 12) {
                    startHour += 12;
                }
                const startMinute = parseInt(startHourMinute[1], 10);

                const endSplit = row.end_at.split(' ');
                const endHourMinute = endSplit[0].split(':');
                const isPMEnd = endSplit[1].toLowerCase() === 'pm';
                let endHour = parseInt(endHourMinute[0], 10);
                if (isPMEnd && endHour !== 12) {
                    endHour += 12;
                }
                const endMinute = parseInt(endHourMinute[1], 10);

                startTime.setHours(startHour, startMinute, 0);
                endTime.setHours(endHour, endMinute, 0);

                const currentTime = serverTime && !isNaN(Date.parse(serverTime)) ? new Date(serverTime) : new Date();
                //const currentTime = new Date();

                var isDisabled = currentTime >= startTime && currentTime <= endTime;
                //isDisabled=true;

                /* if(row?.id === 2513) {
                    row.status = 'started';
                } */
                
                if (row?.status !== "created" && row?.status !== "started") {
                    isDisabled = false;
                }

                return (
                    <Row>
                        <Col>
                            <a data-vid={`${row?.id}`} href={isDisabled ? `/join-Call/${row?.group_id}` : null} data-show={`${helper.convertDate(currentTime)} - ${helper.convertDate(startTime)} - ${helper.convertDate(endTime)}`} >
                                <button className='btn btn-success btn-sm' disabled={!isDisabled}>Join Session</button>
                            </a>
                        </Col>
                    </Row>
                );
            }
        },
    ]

    const columnsOthers = [
        {
            name: ' S.No.',

            cell: (row, index) => index + 1,
            width: '80px',
        },

        {
            name: ' Session Name',
            selector: (row) => row?.session_name,
        },
        {
            name: ' Batch Name',
            width: '250px',
            selector: (row) => row.batch_name,
        },

        {
            name: 'Action	',
            cell: (row) => {
                const startTime = serverTime && !isNaN(Date.parse(serverTime)) ? new Date(serverTime) : new Date();
                const endTime = serverTime && !isNaN(Date.parse(serverTime)) ? new Date(serverTime) : new Date();
                const startSplit = row.starts_at.split(' ');
                const startHourMinute = startSplit[0].split(':');
                const isPMStart = startSplit[1].toLowerCase() === 'pm';
                let startHour = parseInt(startHourMinute[0], 10);
                if (isPMStart && startHour !== 12) {
                    startHour += 12;
                }
                const startMinute = parseInt(startHourMinute[1], 10);

                const endSplit = row.end_at.split(' ');
                const endHourMinute = endSplit[0].split(':');
                const isPMEnd = endSplit[1].toLowerCase() === 'pm';
                let endHour = parseInt(endHourMinute[0], 10);
                if (isPMEnd && endHour !== 12) {
                    endHour += 12;
                }
                const endMinute = parseInt(endHourMinute[1], 10);

                startTime.setHours(startHour, startMinute, 0);
                endTime.setHours(endHour, endMinute, 0);

                const currentTime = serverTime && !isNaN(Date.parse(serverTime)) ? new Date(serverTime) : new Date();
                //const currentTime = new Date();

                var isDisabled = currentTime >= startTime && currentTime <= endTime;

                /* if(row?.status !== "created" && row?.status !== "started") {
                    isDisabled = false;
                } */
                return (
                    <Row>
                        <Col>
                            <a href={isDisabled ? `/join-Call/${row?.group_id}` : null} data-show={`${helper.convertDate(currentTime)} - ${helper.convertDate(startTime)} - ${helper.convertDate(endTime)}`}>
                                <button className='btn btn-success btn-sm' disabled={!isDisabled}>Join Session</button>
                            </a>
                        </Col>
                    </Row>
                );
            }
        },
    ]

    const customStyles =
    {
        headCells: {
            style: {
                fontSize: '18px'

            },
        },
        cells: {
            style: {
                fontSize: '15px',


            }
        }

    };


    return (
        <div className='p-1 pt-5'>
            <div className='mt-1'>{serverFormatedTime}</div>
            <div className="tabs gap-3 d-flex pl-1 pb-1">
                <button 
                    onClick={() => handleTabChange('personal')} 
                    className={`btn btn-primary ${activeTab === 'personal' ? 'active' : ''}`}
                >
                   (Your Preferred Slot-Personal Sessions)
                </button>
                {userData?.user_type === "user" && (
                    <button 
                        onClick={() => handleTabChange('other')} 
                        className={`btn btn-secondary  ${activeTab === 'other' ? 'active' : ''}`}
                    >
                    (Your Flexible Slot-Other Sessions)
                    </button>
                )}
            </div>

            {activeTab === 'personal' && (
                <DataTable
                    // title='Personal Sessions'
                    columns={columns}
                    data={sessionList}
                    pagination
                    highlightOnHover
                    striped
                    subHeader
                    customStyles={customStyles}
                />
            )}

            {activeTab === 'other' && userData?.user_type === "user" && (
                <>
                    {/* <h4>Other Session</h4> */}
                    <DataTable
                        title=''
                        columns={columnsOthers}
                        data={otherSessionList}
                        pagination
                        highlightOnHover
                        striped
                        customStyles={customStyles}
                    />
                </>
            )}
        </div>
    )
}

export default Section
