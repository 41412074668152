import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Card, Modal, Button, Dropdown } from 'react-bootstrap';
import { useGlobalContext } from "../Lib/AppContext";

function PackageCard({ packageData, handlePacakgeClicked, description }) {
    const handleShow = () => {
        handlePacakgeClicked(packageData)
    }
    const { currency } = useGlobalContext();
    const [price, setPrice] = useState("");
    const [mrp, setMrp] = useState("");

    useEffect (() => {
        if(currency === "INR") {
            setPrice("₹ "+packageData?.package_prices[0]?.indian_price);
            setMrp("₹ "+packageData?.package_prices[0]?.indian_mrp)
        } else {
            setPrice("$ "+packageData?.package_prices[0]?.foreign_price);
            setMrp("$ "+packageData?.package_prices[0]?.foreign_mrp)
        }
    }, [currency])
    // const limitedDescription = description.length > 30 ? description.substring(0, 30) + '...' : description;
    return (
        <>
            <div className="col-sm-6 col-md-4 col-lg-4 mb-2">
                <Card className="shadow-lg bg-body-tertiary rounded cursor-pointer" style={{ width: '100%', height: '100%', padding: '5px', boxShadow: '8px 5px 5px buttonHighlight', border: 'none' }} onClick={handleShow}>
                    <Card.Img variant="top" style={{ border: 'none' }} src={packageData?.image_url} height={250} width={50} />
                    {/* <i class="bi bi-pen"></i>  */}
                    {/* <Editimage /> */}
                    <Card.Body>
                        <Card.Title style={{ fontWeight: 'bold' }}>{packageData?.name}</Card.Title>
                        <Card.Text>
                            <span style={{ fontSize: '14px', color: 'black' }}>
                                {packageData?.short_description}
                                {/* {limitedDescription} */}

                                {/* <p>dshfhij;oi adsgvewufdsi jdshidfsw wjdshfu9dfs rdhsfu9df </p> */}
                            </span>

                            <p className="mi-price" style={{ color: 'black' }}> 
                                From <span style={{ fontSize: '18px', fontWeight: '700', color: 'black' }}>
                                    <span style={{fontSize: '15px', fontWeight: '400', color: '#727070', textDecoration: 'line-through'}}>{mrp}</span> {price}
                                </span> /<span style={{ fontSize: '12px', color: 'black' }}>{packageData?.package_prices[0]?.days}days</span>
                            </p>

                        </Card.Text>

                    </Card.Body>
                    <div className="ls-card-bottom" style={{ display: 'flex', justifyContent: 'center' }}>
                        <button type="Submit" className='primary-btn mt-4 mb-3' style={{ borderRadius: '8px', borderStyle: 'none' }}>Buy Now</button>
                        {/* <div class="ls-card-bottom"><span>Card CTA »</span></div> */}
                    </div>
                </Card>

            </div>
        </>
    );
}

export default PackageCard;