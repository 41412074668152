import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const OrderCard = ({ orderData }) => {
  const [packageWidth, setPackageWidth] = useState(window.innerWidth > 720 ? '80%' : '100%');
  const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem("user_data")));
  useEffect(() => {
    const handleResize = () => {
      setPackageWidth('100%');
      if (window.innerWidth > 720) {
        setPackageWidth('70%');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>

      {orderData &&

        <div className="card shadow-sm bg-white rounded " style={{ flex: ' 1 1 30%', margin: '15px', height: '100%', color: 'black', border: "1px solid black" }}>

          <span className='text-center mt-4' ><b>{orderData?.package?.name}</b></span>
          {orderData?.package &&
            <p className='text-center m-2' style={{ minHeight: '20%' }} ><small dangerouslySetInnerHTML={{ __html: orderData?.package?.short_description }}></small></p>
          }

          {userData?.user_type !== "trainer" &&
            <>
              <div style={{ marginBottom: "10px" }}>
                <span style={{ position: 'relative', float: 'left', marginLeft: '20px', fontWeight: '600', marginTop: '20px' }}> Payment Status </span>
                <span style={{ position: 'relative', float: 'right', marginRight: '20px', marginTop: '20px' }} className='text-capitalize'> {orderData?.payment_status}</span>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <span style={{ position: 'relative', float: 'left', marginLeft: '20px', fontWeight: '600' }}>
                  Order Id
                </span>
                <span style={{ position: 'relative', float: 'right', marginRight: '20px', fontSize: '13px' }}> {orderData?.id}</span>
              </div>
            </>
          }

          {userData?.user_type !== "trainer" &&
            <div style={{ marginBottom: "10px" }}>
              <span style={{ position: 'relative', float: 'left', marginLeft: '20px', fontWeight: '600' }}>
                Total Amount
              </span>
              <span style={{ position: 'relative', float: 'right', marginRight: '20px', fontSize: '13px' }}>Rs - <b>{orderData?.indian_price}</b> </span>
            </div>
            // <span style={{ position: 'relative', float: 'left', marginLeft: '20px', }}>Rs - <b>{}</b></span>
          }
          {orderData?.pack_cat_id === 3 && (
            <span style={{ position: 'relative', fontWeight: '600' }}>
              <Link style={{ border: 'none', display: 'flex', marginLeft: '10%', '--hover-color': 'blue', color: "#7199da" }} to={`/Orders/user_info/${orderData?.id}`}>Health Form</Link>
            </span>
          )}

          <div className='d-sm-flex justify-content-sm-between my-2 '>
            {(orderData?.pack_cat_id !== 4 && orderData?.pack_cat_id !== 3) && (
              <>
                <span style={{ marginLeft: '20px', fontWeight: '600' }}>
                  <Link style={{ border: 'none', display: 'flex' }} className='mx-2 mx-sm-1 mb-2 mb-sm-1 gradient-button' to={`/order/sessions/${orderData?.id}`}>Sessions</Link>
                </span>
                <span style={{ marginRight: '20px' }}>
                  <Link style={{ border: 'none', display: 'flex' }} className='mx-2 mx-sm-1 mt-2 mt-sm-1 gradient-button' to={`/order/files/${orderData?.id}`}>Explore Content</Link>
                </span>
              </>
            )}

            {(orderData?.pack_cat_id > 2 ) && (
              <span style={{ marginRight: '20px', padding:'1rem' }}>
                <Link style={{ border: 'none', display: 'flex' }} className='gradient-button' to={`/order/files/${orderData?.id}`}>Explore Content</Link>
              </span>
            )}
          </div>


        </div>

      }

    </>
  );
};

export default OrderCard;