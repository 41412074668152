import React from "react";
import PackageCard from "./PackageCard";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";

function Package({ packIndex, packageList, handlePackClick }) {
    const packageClicked = (packageData) => {
        //console.log("packageData", packageData)
        handlePackClick(packageData)
    }
    return (
        <>
            <div className="row">
                <h4 className={`m-2 ${packIndex > 0 ? 'mt-5' : ''}`}><b>{packageList?.category_name}</b></h4>
                {packageList?.packages.map((packageData, index) => {
                    return (
                        <>                        
                            <PackageCard packageData={packageData} handlePacakgeClicked={packageClicked} />
                           
                        </>
                    )
                })}
            </div>
        </>
    );
}

export default Package;