import React from 'react'
import Image1 from '../Aesets/images/Priyanka singh - 1.jpg'
import { Col, Container, Image, Row } from 'react-bootstrap'

export default function Video() {
    return (
        <div>
            <h1 style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>Videos</h1>

            <Container>
                {/* <video src=>Video</video> */}
                <a  target='blank' href="https://www.youtube.com/live/y5Z6H5ZTvH0?feature=share"><img width={100} src={Image1} alt="" /> Video</a>

            </Container>

        </div>
    )
}