import Swal from "sweetalert2";
export const helper = {
  sweetalert: { toast, confirm, normalToast },
  Capitalize,
  validateContactNumber,
  validateEmail,
  validateStrongPassword,
  convertDate,
  isNumber
};

export function Capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function validateContactNumber(contactNo) {
  const indianPhoneNumberRegex = /^(\+91|0)?[6789]\d{9}$/;
  return indianPhoneNumberRegex.test(contactNo);
}

export function validateEmail(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

export function validateStrongPassword(password) {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecialChars = /[!@#$%^&*()_+[\]{};':"\\|,.<>?/]+/.test(password);

  return (
    password?.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumbers &&
    hasSpecialChars
  );
}

export function toast(title, icon = "success") {
  Swal.fire({
    title: title,
    icon: icon,
    toast: true,
    timer: 5000,
    timerProgressBar: false,
    showConfirmButton: false,
    position: "top"
  });
}

export function confirm(title, icon = "success", cancelButton = false, cancelText = "No") {
  return Swal.fire({
    title: title,
    icon: icon,
    confirmButtonText: 'Yes',
    showConfirmButton: true,
    showCancelButton: cancelButton,
    confirmButtonColor: '#008bd6',
    cancelButtonColor: '#d33',
    cancelButtonText: cancelText
  });
}

export function normalToast(text) {
  Swal.fire({
    title: text,
    showConfirmButton: false
  });
}

export function capitalizeLetter(string) {
  if (string !== "") {
    const words = string.split(" ");
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    const capitalizedString = capitalizedWords.join(" ");
    return capitalizedString;
  } else {
    return string;
  }
}

export function generateRandomStrings(length = 10) {
  let randomString = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars?.length);
    const randomChar = chars.charAt(randomIndex);
    randomString += randomChar;
  }
  return randomString;
}

export function convertDate(timestampString) {
  const parsedDate = new Date(timestampString);
  //const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };
  const formattedDate = parsedDate.toLocaleString(undefined, options);
  return formattedDate;
}

export function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }