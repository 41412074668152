import React, { useEffect, useState } from 'react'
import OrderModal from '../Model/Order.Modal'
import { Link } from 'react-router-dom'
import OrderCard from './Component/OrderCard'
import { Col, Row } from 'react-bootstrap'

const Package = () => {
    const [isLoading, setIsloading] = useState(true);
    const [userpackage, setUserPackage] = useState(false);
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem("user_data")));
    useEffect(() => {
        var condition = {
            payment_status:"paid"
        }
        if(userData?.user_type === "trainer") {
            OrderModal.trainerAllOrder(condition).then((res) => {
                setUserPackage(res.data.data)
                setIsloading(false)
            }).catch((error) => {

            });
        } else {
            OrderModal.userorder(condition).then((res) => {
                console.clear();
                console.log("orders ->", res.data.data);
                setUserPackage(res.data.data)
                setIsloading(false);
            }).catch((error) => {

            });
        }
        
    }, [])

    return (
        <>
            <div>
                {isLoading ? (<div></div>) : (
                    <>
                        <div className='' style={{display:'flex',flexWrap:'wrap',flexDirection:'row'}}>
                            {userpackage?.length > 0 && userpackage.map((orderDetail, index) => {
                                if (orderDetail) {
                                    return (

                                        <Col xs={12} md={6} lg={4} className='mb-3' key={index}>
                                            <OrderCard orderData={orderDetail}  />
                                        </Col>
                                    
                                    )
                                }

                            })}

                            {userpackage?.length === 0 &&
                                <div className='text-center' style={{ marginTop: '2rem' }}>
                                    {userData?.user_type !== "trainer" &&
                                        <p>You have not purchased any packages yet.</p>
                                    }
                                    {userData?.user_type === "trainer" &&
                                        <p>You have not any orders yet.</p>
                                    }
                                </div>
                            }
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default Package
