import React from 'react'
import aboutpic from '../Aesets/images/About_founder.PNG';
import about from '../Aesets/img/about-pic.jfif';
// import aboutsignature from '../Aesets/img/about-signature.png';
// import award from '../Aesets/img/award.jpg';
// import trainer1 from '../Aesets/img/trainer/about-trainer-1.jpg';
// import trainer2 from '../Aesets/img/trainer/about-trainer-2.jpg';
// import trainer3 from '../Aesets/img/trainer/about-trainer-3.jpg';
import TrainingTodaySection from '../Component/TrainingTodaySection';
import AboutSection from '../Component/AboutSection';
import SectionGap from '../Component/SectionGap';
import '../Aesets/css/style.css';
import Instagram from '../Aesets/images/instagram.png';
import Facebook from '../Aesets/images/facebook.png';
import Youtube from '../Aesets/images/youtube.png'


const AboutUs = () => {
    return (
        <>
            <section className="breadcrumb-section set-bg border-0-radius">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-text">
                                <h2>About</h2>
                                <div className="breadcrumb-option">
                                    <a href="index-2.html"><i className="fa fa-home"></i> Home</a><i className="fas fa-chevron-right" style={{ color: 'white' }}></i> <span>About</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AboutSection />
            <section className="about-section about-page spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-text">
                                <h2> Aaryan Rajput Founder AR ELITE FIT</h2>
                                <p className="first-para" style={{ color: 'black' }}>Aryan Rajput is a Certified Personal Trainer and a Nutrition Coach. He founded AR Elite Fit in the year 2018 to help people achieve their fitness goal. With more than seven years of experience in the fitness industry today, he has successfully transformed thousands of clients globally through his knowledge and skills. </p>
                                <p className="second-para" style={{ color: 'black' }}>Although he has completed his Graduation in Hotel Management, his zeal for fitness brought him into this industry. Aryan also has a diploma in Acting but right from his college days he was a keen Fitness Enthusiast. Today he is a successful fitness coach with more than 70 K followers on Instagram and have an impressive career graph. His dream is to help people lead a happy and a healthy lifestyle by using his awesome training program and Nutritional plans. </p>
                                {/* <p>After the covid hit the world.. He decided to make his brand online so that he can reach out of as many people all over the world and helping them to lose weight or also to control and cure diseases Virtually.</p> */}
                                <div
                                    className="footer-social"
                                >
                                    <a target='blank' style={{ fontSize: '35px' }} href="https://www.facebook.com/profile.php?id=100012312541476&mibextid=LQQJ4d">
                                        {/* <i className="fab fa-facebook"></i> */}
                                        <img width={40} src={Facebook} alt="" />
                                    </a>
                                    {/* <a href="#"><i className="fab fa-twitter"></i></a> */}
                                    <a target='blank' style={{ fontSize: '35px', paddingLeft: '3px' }} href="https://instagram.com/aaryanrajputofficial?igshid=MzRlODBiNWFlZA==">
                                        {/* <i className="fab fa-instagram"></i> */}
                                        <img width={40} src={Instagram} alt="" />
                                    </a>
                                    <a target='blank' style={{ fontSize: '35px', paddingLeft: '3px' }} href="https://youtube.com/@aaryanrajputfitness2771">
                                        {/* <i className="fab fa-youtube"></i> */}
                                        <img width={40} src={Youtube} alt="" />
                                    </a>
                                   
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-pic" style={{ justifyContent: 'center', display: 'flex' }}>
                                <img src={about} alt="" style={{ maxHeight: '550px', width: 'auto' }} />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-section about-page spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-pic" style={{ justifyContent: 'center', display: 'flex' }}>
                                <img src={aboutpic} alt="" style={{ maxHeight: '60vh', width: '' }} />

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-text">
                                <h2>Monalisa Dhar Co-Founder AR ELITE FIT</h2>
                                <p className="first-para" style={{ color: 'black' }}>Monalisa is one of the most popular Fitness and Dance coach in India and across the globe. She is a Postgraduate with MBA, M.A in classical dance, Qualified Personal Trainer, and a Qualified Dance Fitness Instructor with over 10 years in the industry. She is working as a successful Dance Instructor and a fitness coach from last five years now.  </p>
                                <p className="second-para" style={{ color: 'black' }}>Monalisa believes in Achieving SMART goals when it comes to fitness and health. She is a workaholic and leaves no stone unturned in motivating her clients and help them reach their fitness goal. </p>
                                <div
                                    className="footer-social"
                                >
                                    <a target='blank' style={{ fontSize: '35px' }} href="https://www.facebook.com/monalisa.dhar.7?mibextid=LQQJ4d">
                                        {/* <i className="fab fa-facebook"></i> */}
                                        <img width={40} src={Facebook} alt="" />
                                    </a>
                                    {/* <a href="#"><i className="fab fa-twitter"></i></a> */}
                                    <a target='blank' style={{ fontSize: '35px', paddingLeft: '3px' }} href="https://instagram.com/move_with_monalisa?igshid=MzRlODBiNWFlZA==">
                                        {/* <i className="fab fa-instagram"></i> */}
                                        <img width={40} src={Instagram} alt="" />
                                    </a>
                                    <a target='blank' style={{ fontSize: '35px', paddingLeft: '3px' }} href="https://youtube.com/@monalisadhar13">
                                        {/* <i className="fab fa-youtube"> </i> */}
                                        <img width={40} src={Youtube} alt="" />
                                    </a>

                                   
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="about-counter">
                <div className="container">
                    <div className="row">
                        <div className="about-counter-text row">
                            <div className="single-counter col-12 col-md-6 col-lg-3">
                                <h1 className="counter-num count">10<span>+</span></h1>
                                <p>Programs</p>
                            </div>
                            <div className="single-counter col-12 col-md-6 col-lg-3">
                                <h1 className="counter-num count">20<span>+</span></h1>
                                <p>Countries</p>
                            </div>
                            <div className="single-counter col-12 col-md-6 col-lg-3">
                                <h1 className="counter-num count">10</h1>
                                <span>k+</span>
                                <p>Transformations</p>
                            </div>
                            <div className="single-counter col-12 col-md-6 col-lg-3">
                                <h1 className="counter-num count">50<span>+</span></h1>
                                <p>Coaches</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <section className="gym-award spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="award-text">
                                <h2>Best gym award</h2>
                                <p>Lorem ipsum proin gravida nibh vel velit auctor aliquet. Aenean pretium sollicitudin,
                                    nascetur auci elit consequat ipsutissem niuis sed odio sit amet nibh vulputate cursus a
                                    amet.</p>
                                <p>Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, gravida quam semper libero
                                    sit amet. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, gravida quam
                                    semper libero sit amet.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img src={award} alt="" />
                        </div>
                    </div>
                </div>
            </section> */}
            <SectionGap />
            <TrainingTodaySection />
            <SectionGap />
            {/* <section className="trainer-section about-trainer spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>EXPERT TRAINERS</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-trainer-item">
                                <img src={trainer1} alt="" />
                                <div className="trainer-text">
                                    <h5>Patrick Cortez</h5>
                                    <span>Leader</span>
                                    <p>non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                                        voluptatem.</p>
                                    <div className="trainer-social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-pinterest"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-trainer-item">
                                <img src={trainer2} alt="" />
                                <div className="trainer-text">
                                    <h5>Gregory Powers</h5>
                                    <span>Gym coach</span>
                                    <p>non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                                        voluptatem.</p>
                                    <div className="trainer-social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-pinterest"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-trainer-item">
                                <img src={trainer3} alt="" />
                                <div className="trainer-text">
                                    <h5>Walter Wagner</h5>
                                    <span>Dance trainer</span>
                                    <p>non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                                        voluptatem.</p>
                                    <div className="trainer-social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-pinterest"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default AboutUs;
