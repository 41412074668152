import React, { useEffect, useState } from 'react';

const UserInfoRadioInput = ({ inputLabel, inputValues = [], inputName, inputDefaultValue = "", hideUsingCheckBox = false, infoId = null }) => {
  const [defValue, setDefValue] = useState(inputDefaultValue);
  const [checkedVal, setCheckedVal] = useState(true);
  useEffect(() => {
    if (hideUsingCheckBox === true) {
      //console.log("checkbox val == ", inputDefaultValue);
      setCheckedVal(null);
      if(infoId !== null) {
        if (inputDefaultValue !== "") {
          setCheckedVal("true");
        } else {
          setCheckedVal("false")
        }
      }
    } else {
      setCheckedVal("true");
    }
  }, [])
  const handleRadioChange = (val) => {
    setDefValue(val);
  };

  /* const handleCheckboxChange = () => {
    setShowInput(!showInput);
  }; */
  const handleCheckboxChange = (event) => {
    setCheckedVal(event.target.value);
  };

  return (
    <>
      <div className='pt-2'>
        <label htmlFor={inputName} className="form-label">
          {inputLabel}
          {hideUsingCheckBox && (
            <>
              <label>
                <input
                  name={`radio-${inputName}`}
                  type='radio'
                  style={{ marginLeft: "15px" }}
                  value="true"
                  onChange={handleCheckboxChange}
                  checked={checkedVal === "true"}
                /> Yes
              </label>
              <label>
                <input
                  name={`radio-${inputName}`}
                  type='radio'
                  style={{ marginLeft: "15px" }}
                  value="false"
                  onChange={handleCheckboxChange}
                  checked={checkedVal === "false"}
                /> No
              </label>
            </>
          )}
        </label><br />
        <div className={`${checkedVal === "true" ? "" : "d-none"}`}>
          {inputValues?.map((inputVal, index) => (
            <div key={`${inputName}-${index}`}>
              <label>
                <input
                  type='radio'
                  value={inputVal}
                  name={inputName}
                  onClick={() => handleRadioChange(inputVal)}
                  checked={defValue === inputVal}
                /> {inputVal}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UserInfoRadioInput;
