import React from "react";
import AboutLogo from '../Aesets/img/new_logo6.JPG';
function AboutSection() {

    return (
        <>
            <section className="about-section spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-text">
                                <h2> About AR Elite Fit</h2>
                                <p className="first-para" style={{color:'black'}}>AR Elite Fit is Online Fitness Training Brand which helps people to achieve their fitness goals, Nutrition consultation, Councelling and much more. AR Elite Fit provides you the variety of fitness sessions such as Functional training, Strength training, Bodyweight training, Cardio, HIIT, Dance Fitness,Kick-Boxing, Yoga, Pilates and so on.</p>
                                <p className="second-para" style={{color:'black'}}>AR Elite Fit has created over 10,000+ successful transformations till now and thrive to do more. Our Vision is to help people in leading a healthy lifestyle with mindful eating and regular exercise. </p>
                                <p style={{color:'black'}}>With Us “YOU CAN BECOME A BETTER VERSION OF YOURSELF”</p>
                                {/* <p className='first-para'>Aaryan Rajput is a Founder of AR ELITE FIT. He is a Certified Personal Trainer and Nutrition Coach. With the experience of more than 7 years in the fitness industry and he has transformed thousands of clients globally through his knowledge and skills. </p>
                                <p className='first-para'>He completed his Graduation in Hotel Management. Also he completed diploma in Acting and from his college days he was a Fitness Enthusiast. Later on he decided to pursue his Fitness passion and to Help People to Get Healthy Lifestyle with Proper Nutrition and Training Information.  </p>
                                <p className='first-para'>After the covid hit the world.. He decided to make his brand online so that he can reach out of as many people all over the world and helping them to lose weight or also to control and cure diseases Virtually.</p> */}

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-pic" style={{
                                display:'flex', 
                                justifyContent:'center',
                                //backgroundColor:'black',
                                height:'95%',
                                paddingBottom:'40px'}}>
                                <img src={AboutLogo} alt="" className="about-logo"  />
                                {/* <a href="https://www.youtube.com/watch?v=SlPhMPnQ58k" className="play-btn video-popup">
                                    <img src={play} alt="" />
                                </a> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutSection;