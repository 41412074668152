import React, { useEffect } from 'react'
import SectionGap from '../Component/SectionGap';
import PackageSection from '../Component/PackageSection';
import { useNavigate } from "react-router-dom";
export default function Subscription() {
    let navigate = useNavigate();
    
    useEffect (() => {
        const userDetail = JSON.parse(sessionStorage.getItem("user_data"));
        if (userDetail?.user_type === "trainer") {
            navigate("/home");
        }
    }, [])
    return (
        <>
            <section className="breadcrumb-section set-bg border-0-radius">
                <div className="container">


                    <div className="row">

                        <div className="col-lg-12">
                            <div className="breadcrumb-text">
                                <h2>Get Subscription</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <SectionGap />

            <section id="subscriptionDiv">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title mb-2 mt-4">
                                <h2>Get Fit and Fabulous at Home</h2>
                                <h3>Choose your Elite Program</h3>
                            </div>
                        </div>
                    </div>
                    <PackageSection />
                </div>

            </section>
        </>
    )
}
