import React from "react";

export default function Termsandcondition() {
  return (
    <div className="container">
      {/* <h2 style={{ marginTop: '30px' }}> Terms & condition</h2>
            <h5 style={{ marginTop: '20px' }}>AGREEMENT TO TERMS</h5>
            <p>
                User Agreement: These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity
                (“you” or “user” or “client”) and Squats, concerning your access to and use of the Website and Fittr Mobile Application as well as any other media form,
                media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Website and Mobile Application”). Squats may have subsidiaries and affiliated legal entities around the world ("Subsidiaries and Affiliates"), providing the Services to you on behalf of Squats. You acknowledge and agree that the Subsidiaries and Affiliates will be entitled to provide
                the Services to you under the terms of this Agreement. You agree that by accessing the Website and/or Mobile Application, you have read, understood, and agree to be bound
                by all of these Terms and Conditions.</p>

            <h5>OUR SERVICES</h5>


            <p>
                Our Services allow you to purchase various Products and services from us. An illustrative list of such Products and services is listed below. We reserve the right to amend, discontinue, withdraw, or cease our service offerings at any time. <br />

                a) Strength and Conditioning Coaching: a session/subscription based offering which allows users to access existing media and content relating to strength and conditioning, and book real-time sessions relating to strength and conditioning. <br />

                b) Yoga Coaching: a session/subscription based offering which allows users to access existing media and content relating to yoga, and book real-time sessions relating to yoga. <br />

                c) Martial Arts Coaching: a session/subscription based offering which allows users to access existing media and content relating to martial arts, and book real-time sessions relating to martial arts. <br />

                d) Zumba Coaching: a session/subscription based offering which allows users to access existing media and content relating to Zumba, and book real-time sessions relating to Zumba. <br />

                e) Nutrition and Fitness Coaching: a session/subscription based offering which allows users to access existing media and content relating to nutrition and fitness. <br />

                f) Mindfulness Coaching: a session/subscription based offering which allows users to access existing media and content relating to mindfulness, and book real-time sessions relating to mindfulness. <br />

                g) Fittr Kids: a session/subscription based offering specially designed for kids and teenagers which allows users access to existing media as well as specially designed/curated courses related to health, fitness and overall development. <br />

                h) Injury Rehab: a session/subscription based offering curated by specialised experts such as physiotherapists which allows users access to existing media as well as live sessions related to injury rehabilitation. <br />

            </p>
            <h5>PRIVACY POLICY</h5>
            <p>
                We care about data privacy and security. Please review our Privacy Policy at:
                By using the Website/Mobile Application, you agree to be bound by our Privacy Policy, which is incorporated into
                these Terms and Conditions. Please be advised the Website/Mobile Application is hosted in the EU Region as per the GDPR Guidelines. If you access the Website/Mobile Application from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in India or EU, then through your continued use of the Website/Mobile Application, you are transferring your data to India and EU, and you expressly consent to have your data transferred to and processed in India and stored in EU region. Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children. If we receive actual knowledge that anyone under the age of 18 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the Website/Mobile Application as quickly as is reasonably practicable.

            </p>
            <h5>TERM AND CONDITION</h5>
            <p>
                These Terms and Conditions shall remain in full force and effect while you use the Website/Mobile Application.

                WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS AND CONDITION, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE WEBSITE/MOBILE APPLICATION (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS AND CONDITIONS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE WEBSITE AND MOBILE APPLICATION OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.

              
              If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p> */}



<h2 style={{ marginTop: '30px' }}> Terms & Condition</h2>
       
     <h4 className="mt-2"></h4>
    <p>Welcome to AR Elite Fit! These Terms and Conditions outline the rules and regulations for the use of the AR Elite Fit Website and
        Application, located at <a href="https://arelitefit.com/" style={{color:"blue"}}> www.arelitefit.com  </a> and accessible through our
        mobile application. 
        </p>
        <p>



        By accessing this website and/or application, we assume you accept these terms and
        conditions. Do not continue to use AR Elite Fit if you do not agree to take all of the terms and conditions
        stated on this page. </p>
        <p>

        The following terminology applies to these Terms and Conditions, Privacy Statement and
        Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person logging in to this
        website and compliant to the Company <b>TM</b> terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us",
        refers to AR Elite Fit. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer
        to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to
        the Client in the most appropriate manner for the express purpose of meeting the Client needs in respect of
        provision of the Company <b>TM</b>  stated services, in accordance with and subject to, prevailing law of INDIA. Any use
        of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are
        taken as interchangeable and therefore as referring to same.</p>
    <h5>1. Services</h5>
    <p>1.1. AR Elite Fit provides a platform for users to access health-related fitness models including Online Personal
        training sessions, group training sessions, Recorded sessions and Nutrition plan.</p>
    <p>1.2. The services provided by AR Elite Fit are subject to availability and may vary based on location and other
        factors.</p>
    <h5>2. User Accounts</h5>
    <p>2.1. In order to access certain features of AR Elite Fit, you may be required to create a user account.</p>
    <p>2.2. You agree to provide accurate and complete information when creating your user account.</p>
    <p>2.3. You are responsible for maintaining the confidentiality of your account and password and for restricting
        access to your account. You agree to accept responsibility for all activities that occur under your account or
        password.</p>
    <h5>3. Payments and Fees</h5>
    <p>3.1. Payment for services provided by AR Elite Fit must be made in accordance with the pricing and payment terms
        specified on the website and/or application.</p>
    <p>3.2. AR Elite Fit reserves the right to change its pricing and payment terms at any time. Any changes will be
        effective immediately upon posting to the website and/or application.</p>
    <h5>4. User Conduct</h5>
    <p>4.1. You agree to use AR Elite Fit for lawful purposes only and in a manner consistent with all applicable laws
        and regulations.</p>
    <p>4.2. You agree not to use AR Elite Fit to harass, abuse, defame, or otherwise infringe upon the rights of
        others.</p>
    <p>4.3. You agree not to upload, post, or otherwise transmit any content that is unlawful, harmful, threatening,
        abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially,
        ethnically, or otherwise objectionable.</p>
    <h5>5. Intellectual Property</h5>
    <p>5.1. The content provided on AR Elite Fit, including but not limited to text, graphics, logos, images, audio
        clips, and video clips, is owned by AR Elite Fit or its licensors and is protected by copyright, trademark, and
        other intellectual property laws.</p>
    <p>5.2. You agree not to reproduce, modify, distribute, display, perform, or otherwise use any content from AR Elite
        Fit without the express written consent of AR Elite Fit.</p>
    <h5>6. Limitation of Liability</h5>
    <p>6.1. To the fullest extent permitted by applicable law, AR Elite Fit shall not be liable for any indirect,
        incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred
        directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your
        access to or use of or inability to access or use the services; (b) any conduct or content of any third party
        on the services.</p>
    <h5>7. Governing Law</h5>
    <p>7.1. These Terms and Conditions shall be governed by and construed in accordance with the laws of INDIA, without
        regard to its conflict of law principles.</p>
    <h5>8. Changes to Terms and Conditions</h5>
    <p>8.1. AR Elite Fit reserves the right to update or modify these Terms and Conditions at any time without prior
        notice. Your use of the website and/or application following any such changes constitutes your agreement to be
        bound by the modified Terms and Conditions.</p>
    <h5>9. Contact Us</h5>
    <p>9.1. If you have any questions about these Terms and Conditions, please contact us at 
        <a  href="mailto:support@arelitefit.com"  style={{color:"blue"}}> support@arelitefit.com </a>
   By using AR Elite Fit, you signify your acceptance of these Terms and Conditions. If you do not agree to these
        Terms and Conditions, please do not use AR Elite Fit.</p>
      <button
        style={{
          border: "none",
          borderRadius: "8px",
          marginBottom: "20px",
          lineHeight: "inherit",
        }}
        className="primary-btn"
      >
        {" "}
        <a href="/" style={{ color: "white", lineHeight: "inherit" }}>
          Go to Home
        </a>
      </button>
    </div>
  );
}
