import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CallingModal from '../../Model/Calling.Modal';
import { Link } from 'react-router-dom';
import { confirm } from '../../Lib/helper';
const Room = () => {
  const { groupId } = useParams();
  const [callLink, setCallLink] = useState(null);
  const userData = JSON.parse(sessionStorage.getItem("user_data"));
  useEffect(() => {
    if(groupId) {
      if(groupId != "") {
        CallingModal.getLink(groupId).then((res) => {
          //console.log("response => ",res?.data?.link)
          if(res?.data?.link) {
            setCallLink(res?.data?.link);
            if(userData?.user_type === "user") {
              /* confirm("Call started ?", "success", true).then((result) => {
                if (result.isConfirmed) {
                  CallingModal.callStarted(groupId).catch((errorUpdate) => {
                    console.log("error ->", errorUpdate);
                  });
                }
              }); */
            }
          }
        }).catch((error) => {
          console.log("Error => ", error);
        })
      }
    }
    
  }, [])
  return (
    <div>
    
      {callLink && (

        <iframe src={callLink} style={{height:'90vh', width:'100%'}}></iframe>
      )}
    </div>
  );
};

export default Room;