import React from "react";
import "../Aesets/css/style.css";
import { Button } from "react-bootstrap";

import Instagram from "../Aesets/images/instagram.png";
import Facebook from "../Aesets/images/facebook.png";
import Youtube from "../Aesets/images/youtube.png";
import { Link } from "react-router-dom";
import LinkeDin from "../Aesets/images/linkedin1.png";
import Twitter from "../Aesets/images/twitter2.png";

function Footer() {
  return (
    <>
      <footer className="footer-section border-0-radius">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="contact-option">
                <span>Phone</span>
                <p>
                  <a style={{ color: "white" }} href="tel:+919818577384">
                    (+91) 9818577384{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-option">
                <span>Address</span>
                <p>C-47 Basement Near Tirupati Eye Center, Sector 33 Noida 201301</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="contact-option">
                <span>Email</span>
                <p>
                  {" "}
                  <a
                    style={{ color: "white" }}
                    href="mailto:support@arelitefit.com"
                  >
                    support@arelitefit.com
                  </a>
                </p>
              </div>
              
            </div>
            <div className="col-md-2">
              <div className="contact-option">
                <button className='primary-btn' style={{ borderRadius: '8px', borderStyle: 'none' }}>  <Link to="/Contact-Us" style={{color:"white"}}>Contact-Us</Link></button>
              </div>
              
            </div>
          </div>

       

          <div className="copyright-text">
            <ul>
              <li>
                <Link to="/termsandconditon">Terms And Condition</Link>
              </li>
              <li>
                <Link to="/privacy_policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/mediafiles"> Media</Link>
              </li>
            </ul>

            <div className="footer-social">
              <a
                target="blank"
                href="https://instagram.com/ar.elitefit?igshid=MzRlODBiNWFlZA=="
              >
                <img width={30} src={Instagram} alt="" />
              </a>

              <a
                target="blank"
                href="https://www.facebook.com/profile.php?id=100064098729153&mibextid=LQQJ4d"
              >
                <img width={30} src={Facebook} alt="" />
              </a>
              <a target="blank" href="https://www.youtube.com/@arelitefit6675">
                <img width={30} src={Youtube} alt="" />
              </a>
              <a
                target="blank"
                href="https://x.com/arelitefit?s=21&t=Lg09jaejRteiq7_uY4tt7w "
              >
                <img width={30} src={Twitter} alt="" />
              </a>
              <a
                target="blank"
                href="https://www.linkedin.com/in/ar-elite-fit-583b16235?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app "
              >
                <img width={30} src={LinkeDin} alt="" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
