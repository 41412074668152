import React, { useEffect, useState } from 'react';


const UserInfoToggleInput = ({ inputLabel, inputName, inputPlaceholder = "", inputDefaultValue = "", infoId = null }) => {
  const [checkedVal, setCheckedVal] = useState(null);
  const inputCount = Math.random();
  useEffect(() => {
    console.log("info Id for "+inputName+" ----->", infoId);
    if (infoId !== null) {
      if(inputDefaultValue !== "") {
        setCheckedVal("true");
      } else {
        setCheckedVal("false");
      }
    }
  }, []);
  const handleCheckboxChange = (event) => {
    setCheckedVal(event.target.value);
  };
  
  return (
    <>
      <div className='pt-2 pb-2'>
        <label htmlFor={inputName} className="form-label">{inputLabel}
          <label>
            <input
              name={`${inputCount}-${inputName}`}
              type='radio'
              style={{ marginLeft: "15px" }}
              value="true"
              onChange={handleCheckboxChange}
              checked={checkedVal === "true"}
            /> Yes
          </label>
          <label>
            <input
              name={`${inputCount}-${inputName}`}
              type='radio'
              style={{ marginLeft: "15px" }}
              value="false"
              onChange={handleCheckboxChange}
              checked={checkedVal === "false"}
            /> No
          </label>
        </label>
        <input type="text" className={`form-control ${checkedVal === "true" ? "" : "d-none"}`} name={inputName} placeholder={inputPlaceholder} defaultValue={inputDefaultValue} />
      </div>
    </>
  );
};

export default UserInfoToggleInput;