import React, { useEffect, useState } from 'react';
import Auth from '../../Model/Auth.Modal';
import { Button, Form } from 'react-bootstrap';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { helper } from '../../Lib/helper';

const RequestEmailOtp = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [seconds, setSeconds] = useState(300);
  const [showTimer, setShowTimer] = useState(false);
  const [buttonText, setButtonText] = useState("Send Otp");
  const [isBtnDissabled, setIsBtnDissabled] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [resetToken, setResetToken] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    otp: '',
  });
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");
    const form = new FormData();
    form.append("email", formData.email);
    Auth.SendEmailOtp(form).then((response) => {
      if (!response?.data?.is_error) {
        setShowTimer(true);
        setSeconds(300);
        setButtonText("Resend Otp");
        setIsBtnDissabled(true);
        setShowOtp(true);
        helper.sweetalert.toast(response?.data?.message);
      } else {
        helper.sweetalert.toast(response?.data?.message, "warning")
      }
      //console.log(response?.data);
    }).catch((error) => {
      console.log(error?.response?.data?.message);
      setErrorMessage(error?.response?.data?.message)
      helper.sweetalert.toast(error?.response?.data?.message, "warning")
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");
    const form = new FormData(event.target);
    Auth.VerifyEmailOtp(form).then((response) => {
      if (!response?.data?.is_error) {
        //console.log("response ------------------>", response?.data);
        sessionStorage.setItem('accessToken', response?.data?.reset_token);
        helper.sweetalert.toast(response?.data?.message);
        setShowResetForm(true)
      } else {
        helper.sweetalert.toast(response?.data?.message, "warning")
      }
    }).catch((error) => {
      console.log(error?.response?.data?.message);
      setErrorMessage(error?.response?.data?.message)
      helper.sweetalert.toast(error?.response?.data?.message, "warning")
    })
  }

  const handleResetSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");
    const form = new FormData(event.target);
    Auth.ResetEmailPassword(form).then((response) => {
      if (!response?.data?.is_error) {
        console.log("response ------------------>", response?.data);
        sessionStorage.setItem('accessToken', response?.data?.access_token);
        sessionStorage.setItem('user_data', JSON.stringify(response?.data?.data));
        helper.sweetalert.toast(response?.data?.message);
        if (response?.data?.data?.user_type === "trainer") {
          window.location.assign("/Profile");
        } else {
          window.location.assign("/");
        }
      } else {
        helper.sweetalert.toast(response?.data?.message, "warning")
      }
    }).catch((error) => {
      console.log(error?.response?.data?.message);
      setErrorMessage(error?.response?.data?.message)
      helper.sweetalert.toast(error?.response?.data?.message, "warning")
    })
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    let timer;
    let formatText = "";
    if (showTimer && seconds > 0) {
      //console.log("here---------------------->")
      timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      formatText = formatTime(seconds);
      setIsBtnDissabled(true);
      setButtonText("Resend Otp (" + formatText + ")");
    }
    if (seconds === 0) {
      setShowTimer(false);
      setIsBtnDissabled(false);
      setButtonText("Send Otp");
    }
    return () => clearTimeout(timer);
  }, [showTimer, seconds]);

  return (
    <div>
      {showResetForm ? (
        <>
          <Form onSubmit={handleResetSubmit}>
            <div className='error-message'>{errorMessage}</div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontSize: '14px', color: 'black' }}>Password*</Form.Label>
              <Form.Control type="password"
                required
                name='password' />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontSize: '14px', color: 'black' }}>Confirm Password*</Form.Label>
              <Form.Control type="password"
                required
                name='cnf_password' />
            </Form.Group>
            <div className='row'>
              <center>
                <button type='submit' className='btn btn-success'>Reset Password</button>
              </center>
            </div>
          </Form>
        </>
      ) : (
        <>
          <Form onSubmit={handleSubmit}>
            <div className='error-message'>{errorMessage}</div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontSize: '14px', color: 'black' }}>Email*</Form.Label>
              <div className='row'>
                <div className='col-8'>
                  <Form.Control type="email"
                    required
                    name='email'
                    onChange={handleInputChange} />
                </div>
                <div className='col-4'>
                  <button className='primary-btn btn-sm' type="button" style={{ borderRadius: '8px', border: 'none', height: '100%', lineHeight: 'unset', width: 'max-content' }} onClick={handleEmailSubmit} disabled={isBtnDissabled}>
                    {buttonText}
                  </button>
                </div>
              </div>

            </Form.Group>
            {showOtp &&
              <>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label style={{ fontSize: '14px', color: 'black' }}>Otp*</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={6}
                    onChange={handleInputChange}
                    name='otp' />
                </Form.Group>
                <div className='row'>
                  <center>
                    <button type='submit' className='btn btn-success'>Verify Otp</button>
                  </center>
                </div>
              </>
            }
          </Form>
        </>
      )}
    </div>
  )
}

export default RequestEmailOtp
