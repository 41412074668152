import React, { useState } from 'react';
import '../Aesets/css/style.css';
import { Button } from 'react-bootstrap';
// import Gallery from './Gallery';
import Video from './Video'
import GalleryComponent from './Gallery';


import mann from '../Aesets/images/womanpunching.jpeg';
import person1 from '../Aesets/images/Priyanka singh - 2.jpg';
import person2 from '../Aesets/images/Priyanka singh - 3.jpg'; 
import Person3 from '../Aesets/images/Priyanka singh - 1.jpg'; 
const images = [
  mann,
  person1,
  person2,
  Person3,
 
  // Add more image URLs here
];



const Mediafiles = () => {

    const [status, setStatus] = useState(false)
    const [statu, setStatu] = useState(false)

    return (
        <>
            <section className="breadcrumb-section set-bg border-0-radius">
                <div className="container">

                



                    <div className="row">
                        <div className="col-lg-12">
                        <div className="breadcrumb-text">
                                <h2>Media</h2>
                                <div className="breadcrumb-option">
                                    <button onClick={() => setStatus(true)} style={{ border: 'none' ,marginRight:'20px',borderRadius:'8px'}} className='primary-btn'>Gallery</button>
                                    <span> <button onClick={() => setStatu(true)}  style={{ border: 'none',borderRadius:'8px' }} className='primary-btn'> Videos</button> </span>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            {/* <AboutSection /> */}




            {/* <section className="about-section about-page spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-pic" style={{ justifyContent: 'center', display: 'flex' }}>
                                <img src={aboutpic} alt="" style={{ maxHeight: '60vh', width: '' }} />

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-text">
                                <h2>Monalisa Dhar Co-Founder AR ELITE FIT</h2>
                                <p className="first-para">She is one of the Influencial Health, Fitness and Dance coach in India and across globe. She is  Post Graduated with MBA, M.A in classical dance, Qualified Personal Trainer
                                    Qualified Dance Fitness Instructor holding an experience of over 10 years as Dance Instructor and over 5 years in Fitness Industry.  </p>
                                <p className="second-para">She Believes in Achieving SMART goals when it comes to fitness, As a individual this particular term will help a person to attain their fitness goal.</p>
                                <div 
                                className="footer-social"
                                >
                                    <a style={{ fontSize: '35px' }} href="#">
                                      
                                        <img width={40} src={Facebook} alt="" /> 
                                        </a>
                                    
                                    <a style={{ fontSize: '35px',paddingLeft:'2px' }} href="https://instagram.com/move_with_monalisa?igshid=MzRlODBiNWFlZA==">
                                       
                                        <img width={40} src={Instagram} alt="" />
                                        </a>
                                    <a style={{ fontSize: '35px',paddingLeft:'2px' }} href="https://youtube.com/@monalisadhar13">
                                         
                                         <img width={40} src={Youtube} alt="" />                                       
                                        </a>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <div className="about-counter">
                <div className="container">
                    <div className="row">
                        <div className="about-counter-text row">
                            <div className="single-counter col-12 col-md-6 col-lg-3">
                                <h1 className="counter-num count">10<span>+</span></h1>
                                <p>Programs</p>
                            </div>
                            <div className="single-counter col-12 col-md-6 col-lg-3">
                                <h1 className="counter-num count">20<span>+</span></h1>
                                <p>Countries</p>
                            </div>
                            <div className="single-counter col-12 col-md-6 col-lg-3">
                                <h1 className="counter-num count">10</h1>
                                <span>k+</span>
                                <p>Members</p>
                            </div>
                            <div className="single-counter col-12 col-md-6 col-lg-3">
                                <h1 className="counter-num count">10<span>+</span></h1>
                                <p>Coaches</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <SectionGap /> */}
            {/* <TrainingTodaySection /> */}
            {/* <SectionGap /> */}


            <div>
                
                    <GalleryComponent images={images} />
                

            </div>

            <div>
                {
                    statu ? <Video/> : null
                }

            </div>

        </>
    )
}

export default Mediafiles;
