import React, { useState } from 'react';
import Auth from '../../Model/Auth.Modal';
import { Button, Form } from 'react-bootstrap';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

const LoginEmail = ({setResetModal}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {

    event.preventDefault();
    setErrorMessage("");
    const form = new FormData(event.target);
    form.append("platform", "web")
    Auth.loginbyemail(form)
      .then((response) => {
        const accessToken = response?.data?.access_token;
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('user_data', JSON.stringify(response?.data?.data));
        if (response?.data?.data?.user_type === "trainer") {
          window.location.assign("/Profile");
        } else {
          window.location.assign("/");
        }
      })
      .catch((error) => {
        console.log(error?.response?.data?.message);
        setErrorMessage(error?.response?.data?.message)
      });
  };


  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='error-message'>{errorMessage}</div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{ fontSize: '14px', color: 'black' }}>Email / Mobile*</Form.Label>
          <Form.Control type="text"
            required
            className='inputBorderGreyColor'
            value={formData.email}
            name='email'
            onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label style={{ fontSize: '14px', color: 'black' }}>Password*</Form.Label>
          <div className="input-group">
            <Form.Control
              type={showPassword ? "text" : "password"}
              required
              className='inputBorderGreyColor'
              value={formData.password}
              onChange={handleInputChange}

              name='password' />
            <div className="input-group-append inputBorderGreyColor">
              <span
                className="input-group-text"
                onClick={togglePasswordVisibility}
                style={{ cursor: 'pointer', height: '100%' }}
              >
                {showPassword ? <BsEyeSlash /> : <BsEye />}
              </span>
            </div>
          </div>
        </Form.Group>
        <div className='row'>
          <div className='col'>
            <button className='primary-btn' type="submit" style={{ borderRadius: '8px', border: 'none' }}>
              Submit
            </button>
          </div>
          <div className='col'>
            <button type='button' onClick={setResetModal} className='btn btn-danger float-end' style={{fontSize:'16px', height:'46px'}}>
              Forget Password
            </button>
          </div>
        </div>
        
      </Form>
    </div>
  )
}

export default LoginEmail
