import React, { useState } from 'react';

//import logo from '../Aesets/img/1logo.png';
import logo from '../Aesets/img/new_logo6.JPG';
import '../Aesets/css/style.css';
import { Row, Col, Modal, Form, Dropdown, Container, Nav, Navbar, Tab, Tabs} from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import Auth from '../Model/Auth.Modal';
import Loginemail from './login/loginemail';
import RequestEmailOtp from './ResetComponent/RequestEmailOtp';
import { useEffect } from 'react';
import { validateContactNumber, validateEmail, validateStrongPassword, isNumber } from '../Lib/helper';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
// import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useGlobalContext } from '../Lib/AppContext';

function Header() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userdetls, setuserdetls] = useState();
  const [firstName, setFirstName] = useState("");
  const [key, setKey] = useState('home');
  const [show, setShow] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    contact_no: '',
    password: '',
    gender: '',
    user_age: 0,
    height: 0,
    body_fat: '',
    exp_level: 'Beginer',
    medical_condition: '',
    goals: '',
  });

  const allDefErrors = {
    full_name: '',
    gender: '',
    email: '',
    contact_no: '',
    password: '',
    user_age: '',
    height: '',
    body_fat: '',
    exp_level: '',
    medical_condition: '',
    goals: '',
  }

  const [fieldErrors, setFieldErrors] = useState(allDefErrors);

  const handleClose = () => {
    setShow(false);
    setFieldErrors(allDefErrors)
    setStep(1); // Reset the step when closing the modal
  };

  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showSubscription, setShowSubscription] = useState(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const validateStep1 = () => {
    return new Promise((resolve) => {
      const { full_name, gender, email, contact_no, password } = formData;
      const errors = {};

      if (!full_name) {
        errors.full_name = 'Full Name is required';
      }
      if (!gender) {
        errors.gender = 'Gender is required';
      } else if(gender === "") {
        errors.gender = 'Gender is required';
      }

      if (!email) {
        errors.email = 'Email is required';
      } else if (!validateEmail(email)) {
        errors.email = 'Invalid email address';
      }
      if (!contact_no) {
        errors.contact_no = 'Contact No is required';
      }
      if (!password) {
        errors.password = 'Password is required';
      } else if (!validateStrongPassword(password)) {
        //errors.password = 'Password must be strong';
      }

      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        ...errors,
      }));

      const isValid = Object.keys(errors).length === 0;

      resolve(isValid); // Resolve the promise with the validation result
    });
  };


  const [step, setStep] = useState(1);
  const handleNext = async () => {
    setFieldErrors(allDefErrors);
    if (step === 1) {
      const isValid = await validateStep1();
      if (isValid) {
        setStep(2);
      }
    }
  }
  const handleBack = () => {
    setStep(1);
    setFieldErrors(allDefErrors)
  }

  const handleInputChange = (event) => {
    if (event.target.name === "contact_no") {
      if (/^\+[0-9+]+$/.test(event.target.value) || /^[0-9+]+$/.test(event.target.value)) {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      } else if (event.target.value === "") {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
    /* setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    }); */
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { currency, setCurrency } = useGlobalContext();
  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");
    const form = new FormData();
    for (const fieldName in formData) {
      form.append(fieldName, formData[fieldName]);
    }
    setIsBtnDisabled(true);
    Auth.Registration(form)
      .then((response) => {
        const accessToken = response.data.access_token;
        sessionStorage.setItem('accessToken', accessToken);
        setLoggedIn(response.data.access_token === (false))
        sessionStorage.setItem('user_data', JSON.stringify(response?.data?.data));
        window.location.assign("/");
      }).catch((error) => {
        setIsBtnDisabled(false);
        console.log(error?.response);
        setErrorMessage(error?.response?.data?.message)
      });
  };
  useEffect(() => {
    if (sessionStorage.getItem("user_data")) {
      const userDetail = JSON.parse(sessionStorage.getItem("user_data"));
      setuserdetls(userDetail);
      setFirstName(userDetail?.full_name?.split(' ')[0]);
      if (userDetail?.user_type === "trainer") {
        setShowSubscription(false);
      }
    }
  }, [])
  
  

  const location = useLocation();
  const hashUrls = location.hash.substring(1);
  const [currentUrl, setCurrentUrl] = useState(window.location.pathname.substring(1));
  useEffect(() => {
    const newUrl = location.pathname.substring(1);
    //console.log("url = ", location.pathname, hashUrls)
    if(hashUrls) {
      setCurrentUrl(hashUrls);
    } else {
      setCurrentUrl(newUrl);
    }
  }, [location.pathname, hashUrls]);

  const showResetModalFunction = () => {
    setShowResetModal(true);
    setShow2(false);
  }

  const hideResetModalFunction = () => {
    setShowResetModal(false);
  }
  return (
    <>

      <Navbar collapseOnSelect expand="lg" className="bg-black border-0-radius" style={{ padding: '10px 0px 10px' }}>
        <Container>
          <Navbar.Brand href="#home" className='text-white ms-0 me-5 pt-0 pb-0'>
            <Link to='/'>
              <img src={logo} alt="" height={45} style={{borderRadius:'50%'}} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className='bg-white' />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto " style={{ gap: '10px' }}>
              <Nav.Link as={Link} to='/' className={`menu-links ${currentUrl === '' ? 'active' : ''} `}>Home</Nav.Link>
              <Nav.Link as={Link} to='/AboutUs' className={`menu-links ${currentUrl === 'AboutUs' ? 'active' : ''} `}>About</Nav.Link>
              {showSubscription &&
                <Nav.Link as={Link} to='/Subscription' className={`menu-links ${currentUrl === 'Subscription' ? 'active' : ''} `}>Our Plans</Nav.Link>
              }
              {/* <Nav.Link as={Link} to='/Contact-Us' className={`menu-links ${currentUrl === 'Contact-Us' ? 'active' : ''} `}>Contact Us</Nav.Link> */}
               
              <Nav.Link as={Link} to='/#testimonials' className={`menu-links ${currentUrl === 'testimonials' ? 'active' : ''} `}>Testimonial</Nav.Link>
              {showSubscription &&
                <Nav.Link as={Link} to='/#join-our-team' className={`menu-links ${currentUrl === 'join-our-team' ? 'active' : ''} `}>Join Our Team</Nav.Link>
              }
              <Nav.Link as={Link} to='/blog' className={`menu-links ${currentUrl === 'blog' ? 'active' : ''} `}>Blogs</Nav.Link>
            </Nav>
            <Nav style={{ gap: '10px' }}>
              {sessionStorage.getItem("user_data") ? (
                <>
                  <span className='signup-btn text-light' style={{ padding: '10px 15px' }}>Welcome,  {firstName}</span>
                  <Link to='/Profile' className="primary-btn signup-btn ">Dashboard</Link>
                </>
              ) : (
                <>
                  {/* <div style={{display:'flex',justifyContent:'space-between'}}> */}
                  <Link onClick={handleShow2} style={{ width: '90px' }} className="primary-btn signup-btn">Login</Link>
                  <Link onClick={handleShow} style={{ width: '90px' }} className="primary-btn signup-btn ">Sign Up</Link>
                  {/* </div> */}
                </>
              )}
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" style={{height:'46px', backgroundColor:'black', borderColor:'black'}}>
                  Currency : {currency} {currency === "INR" ? "₹":"$"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={()=> {setCurrency("INR");}}>INR ₹</Dropdown.Item>
                  <Dropdown.Item onClick={()=> {setCurrency("USD");}}>USD $</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal style={{ marginTop: '20px' }} show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title style={{ fontSize: '24px', fontWeight: 'bold', width:'100%' }}>
            <center>Let’s Elevate your Fitness with<br />AR ELITE FIT</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div>
              <div className='error-message'>{errorMessage}</div>
              {step === 1 && (
                <Container>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: '14px', color: 'black' }}>Full Name*</Form.Label>
                        <Form.Control type="text"
                          value={formData.full_name}
                          name='full_name'
                          required
                          className='inputBorderGreyColor'
                          style={{ fontSize: '13px' }}
                          onChange={handleInputChange} />
                        {fieldErrors.full_name && <div className="error-message">{fieldErrors.full_name}</div>}
                      </Form.Group>

                      <Form.Group className="mb-2" controlId="formBasicGender">
                        <Form.Label style={{ fontSize: '14px', color: 'black' }}> Gender*</Form.Label>
                        <div class="form-check form-check-inline" style={{ marginLeft: '20%' }}>
                          <input class="form-check-input inputBorderGreyColor" type="radio" name="gender" id="inlineRadio1" value="Male" onChange={handleInputChange} />
                          <label class="form-check-label" htmlFor="inlineRadio1">Male</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input inputBorderGreyColor" type="radio" name="gender" id="inlineRadio2" value="Female" onChange={handleInputChange} />
                          <label class="form-check-label" htmlFor="inlineRadio2">Female</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input inputBorderGreyColor" type="radio" name="gender" id="inlineRadio3" value="Other" onChange={handleInputChange} />
                          <label class="form-check-label" htmlFor="inlineRadio3">Other </label>
                        </div>
                        {fieldErrors.gender && <div className="error-message">{fieldErrors.gender}</div>}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: '14px', color: 'black' }}>Email*</Form.Label>
                        <Form.Control type="email"
                          // placeholder="Enter Email"
                          value={formData.email}
                          name='email'
                          required
                          className='inputBorderGreyColor'
                          style={{ fontSize: '13px' }}
                          onChange={handleInputChange} />
                        {fieldErrors.email && <div className="error-message">{fieldErrors.email}</div>}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: '14px', color: 'black' }}>Contact No*</Form.Label>
                        <Form.Control 
                          type="text"
                          // placeholder="Enter Contact No"
                          value={formData.contact_no}
                          onChange={handleInputChange}
                          style={{ fontSize: '13px' }}
                          maxLength="13"
                          className='inputBorderGreyColor'
                          required
                          name='contact_no' />
                        {fieldErrors.contact_no && <div className="error-message">{fieldErrors.contact_no}</div>}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: '14px', color: 'black' }}>Password*</Form.Label>
                        <div className="input-group">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            // placeholder="Password"
                            value={formData.password}
                            required
                            style={{ fontSize: '13px' }}
                            onChange={handleInputChange}
                            className='inputBorderGreyColor'
                            name='password' />
                          <div className="input-group-append inputBorderGreyColor">
                            <span
                              className="input-group-text"
                              onClick={togglePasswordVisibility}
                              style={{ cursor: 'pointer', height: '100%' }}
                            >
                              {showPassword ? <BsEyeSlash /> : <BsEye />}
                            </span>
                          </div>
                        </div>
                        {fieldErrors.password && <div className="error-message">{fieldErrors.password}</div>}
                      </Form.Group>

                      <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                        <button type="button" onClick={handleClose} style={{ borderRadius: '8px', borderStyle: 'none', paddingLeft: '20px', paddingRight: '20px', height: '46px', marginTop: '5px' }}>Close</button>
                        <button onClick={handleNext} type="button" className='primary-btn mt-2' style={{ borderRadius: '8px', borderStyle: 'none' }}>Next</button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}

              {step === 2 && (
                <Container>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicAge">
                        <Form.Label style={{ fontSize: '14px', color: 'black' }}>Age*</Form.Label>
                        <Form.Control type="text"
                          value={formData.user_age}
                          name='user_age'
                          required
                          className='inputBorderGreyColor'
                          placeholder='0'
                          style={{ fontSize: '13px' }}
                          onChange={handleInputChange} />
                      </Form.Group>

                      <Form.Group className="mb-2" controlId="formBasicHeight">
                        <Form.Label style={{ fontSize: '14px', color: 'black' }}>Height*</Form.Label>
                        <Form.Control type="text"
                          value={formData.height}
                          name='height'
                          min="1"
                          placeholder='0'
                          required
                          className='inputBorderGreyColor'
                          style={{ fontSize: '13px' }}
                          onChange={handleInputChange} />
                      </Form.Group>


                      <Form.Group className="mb-2" controlId="formBasicBodyFat">
                        <Form.Label style={{ fontSize: '14px', color: 'black' }}>Body Fat</Form.Label>
                        <Form.Control type="text"
                          placeholder="Body Fat"
                          value={formData.body_fat}
                          name='body_fat'
                          required
                          className='inputBorderGreyColor'
                          style={{ fontSize: '13px' }}
                          onChange={handleInputChange} />
                      </Form.Group>

                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '5px' }}>
                        <div style={{ fontSize: '14px', color: 'black', width: '30%', paddingTop: '5px' }}>What is level -</div>
                        <div style={{ width: '80%' }}>

                          <Form.Group className="mb-2" controlId="formBasicBodyFat">

                            <select value={formData.exp_level} className='form-control inputBorderGreyColor' name='exp_level' onChange={handleInputChange}>
                              <option value="Beginer">Beginer</option>
                              <option value="Intermediate">Intermediate</option>
                              <option value="Advance">Advance</option>
                            </select>
                          </Form.Group>
                        </div>
                      </div>

                      <Form.Group className="mb-2" controlId="formBasicMedicalCondition">
                        <Form.Label style={{ fontSize: '14px', color: 'black' }}>Any medical conditon or Allergies</Form.Label>
                        <Form.Control type="text"
                          placeholder="If any medical condition please specify"
                          value={formData.medical_condition}
                          required
                          className='inputBorderGreyColor'
                          style={{ fontSize: '13px' }}
                          onChange={handleInputChange}
                          name='medical_condition' />
                      </Form.Group>

                      <Form.Group className="mb-2" controlId="formBasicGoals">
                        <Form.Label style={{ fontSize: '14px', color: 'black' }}>Goals</Form.Label>
                        <Form.Control type="text"
                          placeholder="Goals"
                          value={formData.goals}
                          required
                          className='inputBorderGreyColor'
                          style={{ fontSize: '13px' }}
                          onChange={handleInputChange}
                          name='goals' />
                      </Form.Group>

                      <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                        <button type="button" onClick={handleBack} style={{ borderRadius: '8px', borderStyle: 'none', paddingLeft: '20px', paddingRight: '20px', height: '46px', marginTop: '5px' }}>Back</button>

                        <button type="submit" disabled={isBtnDisabled} className='primary-btn mt-2' style={{ borderRadius: '8px', borderStyle: 'none' }}>Submit</button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </div>
          </Form>

        </Modal.Body>
      </Modal >
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '30px' }}>Login  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                >
                  {/* <Tab eventKey="home" title="Login with OTP">
                    <Loginotp />
                  </Tab> */}
                  <Tab eventKey="home" title="Login with Email">
                    <Loginemail setResetModal={showResetModalFunction} />
                  </Tab>

                </Tabs>
              </Col>
            </Row>
          </Container>
        </Modal.Body>

      </Modal>

      <Modal show={showResetModal} onHide={hideResetModalFunction}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '30px' }}>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <RequestEmailOtp />
            </Row>
          </Container>
        </Modal.Body>

      </Modal>
    </>
  );
}

export default Header;