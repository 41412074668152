import React, { useEffect, useState } from 'react';

import OrderModal from '../Model/Order.Modal';
import { useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { helper } from '../Lib/helper';
const OrderFiles = () => {
    const [fileLists, setFileLists] = useState();
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem("user_data")));
    const params = useParams();
    useEffect(() => {
        OrderModal.userOrderPackageFileList(params.id).then((res) => {
            setFileLists(res?.data?.data);
            
            console.log("file list", res?.data?.data)
        }).catch((error) => {
            console.log("error fetching order sessions");
        });

    }, [])
    const columns = [
        {
            name: ' S.No.',

            cell: (row, index) => index + 1,
            width: '80px',
        },

        {
            name: ' Title',
            selector: (row) => row.title,
        },
        {
            name: ' Description',
            width: '250px',
            selector: (row) => row.description,
        },
        {
            name: 'Link	',
            cell: (row) => {
                
                return (
                    <Row>
                        <Col>
                            <a href={row.file_url} target='_blank' className='btn btn-success btn-sm' >
                                Show File
                            </a>
                        </Col>
                    </Row>
                );
            }
        },
    ]

    const customStyles =
    {
        headCells: {
            style: {
                fontSize: '18px'

            },
        },
        cells: {
            style: {
                fontSize: '15px',


            }
        }

    };


    return (
        <div className='p-1 pt-5'>
            <DataTable
                title='Order Files'
                columns={columns}
                data={fileLists}
                pagination
                highlightOnHover
                striped
                subHeader
                customStyles={customStyles}
            />
        </div>
    )
}

export default OrderFiles
