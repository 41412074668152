// import React from "react";
// import Imageoftaining from '../Aesets/images/gettrainingtoday.jpeg'

// function TrainingTodaySection() {

//     return (
//         <>
//             {/* <section
//              className="banner-section set-bg-training"
//              >               
//                 {/* <img className="d-block w-100 " src={Imageoftaining} alt="First slide" /> */}
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-6">
//                             <div className="banner-text" style={{ paddingTop: '50px', paddingBottom: '100px' }}>
//                                 <h2>Get training today</h2>
//                                 <p style={{ fontSize: '13px' }}>Ready to take your fitness to the next level? Look no further than AR Elite Fit. Our training programs are designed to help you achieve your fitness goals, whether you're a beginner or an advanced athlete. From personalized workouts to expert guidance, our team of experienced trainers is here to support you every step of the way. Don't wait any longer to start your fitness journey. Sign up for training with AR Elite Fit today and unlock your full potential.</p>
//                                 <a href="#" className="primary-btn banner-btn">Contact Now</a>
//                             </div>
//                         </div>
//                         {/* <div className="col-lg-5">
//                             <img src="img/banner-person.png" alt="" />
//                         </div>
//                     </div>
//                 </div>
//             </section> */}
//         </>
//     );
// }

// export default TrainingTodaySection;

const TrainingTodaySection = () => {
    return <></>
}

export default TrainingTodaySection;