import React from 'react';
 
import { Outlet } from 'react-router';
import Sidenav from "./Component/Sidebar";
import Topnav from "./Component/Header";

function  CallLayout()  {
  return (
    <>
      <div>
        <Topnav />
        <div className="container-fluid" >
          <div className='row mb-3'>
            
            <div className="col-12">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CallLayout;