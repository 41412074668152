// import React from 'react'
// import Image1 from '../Aesets/images/Priyanka singh - 1.jpg'
// import { Col, Container, Image, Row } from 'react-bootstrap'

// export default function Gallery() {
//   return (
//     <div>
//         <h1 style={{display:'flex',justifyContent:'center',margin:'20px'}}>Gallery</h1>
        
//         <Container>
//       <Row>
//         <Col xs={6} md={3}>
//           <Image width={200} src={Image1} thumbnail  />
//         </Col>
//         <Col xs={6} md={3}>
//           <Image width={200}  src={Image1} thumbnail  />
//         </Col>
//         <Col xs={6} md={3}>
//           <Image width={200}  src={Image1} thumbnail />
//         </Col>
//         <Col xs={6} md={3}>
//           <Image width={200}  src={Image1} thumbnail />
//         </Col>
//       </Row>

//       <Row style={{marginTop:'20px',marginBottom:'20px'}}>
//         <Col xs={6} md={3}>
//           <Image width={200} src={Image1} thumbnail  />
//         </Col>
//         <Col xs={6} md={3}>
//           <Image width={200}  src={Image1} thumbnail  />
//         </Col>
//         <Col xs={6} md={3}>
//           <Image width={200}  src={Image1} thumbnail />
//         </Col>

//         <Col xs={6} md={3}>
//           <Image width={200}  src={Image1} thumbnail />
//         </Col>
//       </Row>
//     </Container>

//     </div>
//   )
// }





import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Aesets/css/new.css'


const GalleryComponent = ({ images }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const openImage = (image) => {
        setSelectedImage(image);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    return (
        <div className="gallery-container container">
            <div className="row">
                {images.map((image, index) => (
                    <div key={index} className="col-md-3">
                        <img
                            src={image}
                            alt={`Image ${index}`}
                            className="img-thumbnail"
                            onClick={() => openImage(image)}
                        />
                    </div>
                ))}
            </div>

            {selectedImage && (
                <div className="lightbox">
                    <span className="close-btn" onClick={closeImage}>
                        &times;
                    </span>
                    <img src={selectedImage} alt="Selected" className="lightbox-img" />
                </div>
            )}
        </div>
    );
};

export default GalleryComponent;
