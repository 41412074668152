import { useState, useEffect } from 'react';
import image1 from '../Aesets/images/Priyanka singh - 24.jpg';
import image2 from '../Aesets/images/Priyanka singh - 18.jpg';
import image3 from '../Aesets/images/Priyanka singh - 22.jpg';
import image4 from '../Aesets/images/Priyanka singh - 25.jpg';

import image5 from '../Aesets/images/Priyanka singh - 3.jpg';
import image6 from '../Aesets/images/Priyanka singh - 6.jpg';
import image7 from '../Aesets/images/Priyanka singh - 23.jpg';
import image8 from '../Aesets/images/Priyanka singh - 15.jpg';

import image9 from '../Aesets/images/Priyanka singh - 14.jpg';
import image10 from '../Aesets/images/Priyanka singh - 11.jpg';
import image11 from '../Aesets/images/Priyanka singh - 21.jpg';
import image12 from '../Aesets/images/Priyanka singh - 13.jpg';

import image13 from '../Aesets/images/Priyanka singh - 4.jpg';
import image14 from '../Aesets/images/Priyanka singh - 16.jpg';
import image15 from '../Aesets/images/Priyanka singh - 20.jpg';
import image16 from '../Aesets/images/Priyanka singh - 12.jpg';

import image17 from '../Aesets/images/Priyanka singh - 17.jpg';
import image18 from '../Aesets/images/Priyanka singh - 15.jpg';
import image19 from '../Aesets/images/Priyanka singh - 8.jpg';
import image20 from '../Aesets/images/Priyanka singh - 7.jpg';

import image21 from '../Aesets/images/Priyanka singh - 1.jpg';
import image22 from '../Aesets/images/Priyanka singh - 2.jpg';
import image23 from '../Aesets/images/Priyanka singh - 5.jpg';
import image24 from '../Aesets/images/Priyanka singh - 10.jpg';
import Auth from '../Model/Auth.Modal';
import { Carousel } from 'react-bootstrap';
// import '../Styles/Testimonials.css'; // Assuming you have a CSS file for custom styles

const TestimonialSection = () => {

  const [testimonialImages, setTestimonialImages] = useState([
    image1, image2, image3, image4, image5, image6, image7, image8,
    image9, image10, image11, image12, image13, image14, image15,
    image16, image17, image18, image19, image20, image21, image22,
    image23, image24
  ]);

  const getTestimonials = () => {
    Auth.GetTestimonials().then((res) => {
      const tempTestimonial = res?.data?.data?.map(resultData => resultData?.image) || [];
      setTestimonialImages(tempTestimonial);
    }).catch((error) => {
      console.log("testimonials error", error.message);
    });
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  const [testimonialItems, setTestimonialItems] = useState([]);
  
  const setTestimonialItem = () => {
    const tempTestimonialItems = [];
    const screenWidth = window.innerWidth;
    let itemsPerRow;
  
    // Determine the number of items per row based on screen width
    if (screenWidth < 600) {
      itemsPerRow = 1; // Small screens
    } else if (screenWidth < 900) {
      itemsPerRow = 2; // Medium screens
    } else {
      itemsPerRow = 3; // Large screens
    }
  
    // Loop through the testimonialImages array and slice it into rows
    for (let i = 0; i < testimonialImages.length; i += itemsPerRow) {
      const row = testimonialImages.slice(i, i + itemsPerRow);
      tempTestimonialItems.push(row);
    }
  
    setTestimonialItems(tempTestimonialItems);
  };

  useEffect(() => {
    // Set items on initial load
    setTestimonialItem();

    // Add event listener for window resize
    const handleResize = () => {
      setTestimonialItem();
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [testimonialImages]);

  return (
    <section className="membership-section spad services-section" id='testimonials'>
      <div className="container">
        <div className="section-title">
          <h2 style={{ fontSize: '50px', fontWeight: '700' }}>CLIENT TESTIMONIALS</h2>
        </div>
        <Carousel interval={4000} nextIcon={<span className="carousel-control-next-icon" />} prevIcon={<span className="carousel-control-prev-icon" />}>
          {testimonialItems.map((row, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex flex-row justify-content-center">
                {row.map((imageSrc, innerIndex) => (
                  <div key={innerIndex} className="p-2">
                    <img className="d-block w-100 img-fluid" src={imageSrc} alt={`Slide ${index + 1}-${innerIndex + 1}`} />
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default TestimonialSection;
