import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Landingpage/Home';
// import AboutUs from './Internalpage/AboutUs';
import Mediafiles from './Internalpage/Mediafiles';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactUs from './Internalpage/ContactUs';

import { Outlet } from 'react-router';
import WithNav from './dashboard/newdashboard';
import WithNavNEW from './Layout/NewNav';
import CallLayout from './dashboard/CallLayout';
import Profile from './dashboard/profile';
import EditProfile from './dashboard/editprofile';
import Package from './dashboard/Packageinfo';
import UserInfo from './dashboard/UserInfo';
import Section from './dashboard/Section';
import PendingSessions from './dashboard/PendingSessions';
import Termsandcondition from './Internalpage/Termsandcondition';
import PrivacyPolicy from './Internalpage/PrivacyPolicy';
import AboutPage from './Internalpage/AboutUs';
import Gallery from './Internalpage/Gallery';
import Subscription from "./Internalpage/Subscription";
import JoinCall from "./dashboard/Calling/Room";
import OrderFiles from './dashboard/OrderFiles';

import { GlobalContextProvider } from "./Lib/AppContext";
import Blog from './Internalpage/Blog';
function App() {
  return (
    <>
      <GlobalContextProvider>
        <Outlet />
        <Router>
          <Routes>
            <Route element={<WithNav />}>
              <Route path='/Profile' element={<Profile />} />
              <Route path='/Edit_profile' element={<EditProfile />} />
              <Route path='/Orders' element={<Package />} />
              <Route path='/order/sessions' element={<Section />} />
              
              <Route path='/Pending-sessions' element={<PendingSessions />} />
              <Route path='/My-sessions' element={<PendingSessions />} />
              <Route path='/order/sessions/:id' element={<Section />} />
              <Route path='/order/files/:id' element={<OrderFiles />} />

              <Route path='/Orders/user_info/:orderId' element={<UserInfo />} />

            </Route>
            <Route element={<WithNavNEW />}>
              <Route path='/' element={<Home />} />
              <Route path='/home' element={<Home />} />
              <Route path='/AboutUs' element={<AboutPage />} />
              <Route path='/Contact-Us' element={<ContactUs />} />
              <Route path='/termsandconditon' element={<Termsandcondition />} />
              <Route path='/privacy_policy' element={<PrivacyPolicy />} />
              <Route path='/mediafiles' element={<Mediafiles />} />
              <Route path='/gallery' element={<Gallery />} />
              <Route path="/Subscription" element={<Subscription />} />
              <Route path="/blog" element={<Blog />} />

            </Route>
            <Route element={<CallLayout />}>
              <Route path='/Join-Call/:groupId' element={<JoinCall />} />
            </Route>
          </Routes>
        </Router>
      </GlobalContextProvider>
    </>
  );
}

export default App;
