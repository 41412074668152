import axiosInstance from "../Lib/axiosInstance";
import { useParams } from "react-router-dom";


const Packages =
{
    async Healthissue(data = []) {
     
        return await axiosInstance.get("health_issue", data);
    },

    async ALlpackage(data){
        return await axiosInstance.get("packages" , data);
    },

    async priceinfo(data){
        return await axiosInstance.post("packages/price_info" ,data)
    },

    async BatchList(data){
        return await axiosInstance.get("order/batches" ,data)
    }
    
}

export default Packages