import axiosInstance from "../Lib/axiosInstance";

const Calling = class {
    async generateToken(data) {
        return await axiosInstance.post("/calling/createToken", data)
    }

    async startSession(channelName) {
        return await axiosInstance.put("/calling/startSession", channelName)
    }

    async endSession(channelName) {
        return await axiosInstance.put("/calling/endSession", channelName)
    }

    async getLink(groupId) {
        return await axiosInstance.get(`/order/get_link/${groupId}`)
    }

    async callStarted(groupId) {
        return await axiosInstance.put(`/order/call_started/${groupId}`)
    }
}

export default new Calling