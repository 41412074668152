import React, { useEffect, useState } from "react";
import OrderModal from "../Model/Order.Modal";
import { useParams } from "react-router-dom";
import { helper } from "../Lib/helper";
import UserInfoToggleInput from "./Component/UserInfoToggleInput";
import UserInfoRadioInput from "./Component/UserInfoRadioInput";

const UserInfo = () => {
  const [isLoading, setIsloading] = useState(true);
  const [userpackage, setUserPackage] = useState(false);
  const params = useParams();
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("user_data"))
  );
  const [userInfo, setUserInfo] = useState([]);
  const [showExcercise, setShowExcercise] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const getUserInfo = async () => {
    //console.log("heererer")
    OrderModal.getUserInfo(params.orderId)
      .then((infoData) => {
        //console.log("userData -> ", infoData?.data?.data?.rows[0])
        if (infoData?.data?.data?.count > 0) {
          setUserInfo(infoData?.data?.data?.rows[0]);
          if (
            infoData?.data?.data?.rows[0]?.type_of_excercise !== "" ||
            infoData?.data?.data?.rows[0]?.excercise_frequency !== "" ||
            infoData?.data?.data?.rows[0]?.excercise_duration !== "" ||
            infoData?.data?.data?.rows[0]?.excercise_limitation !== ""
          ) {
            setShowExcercise("true");
          } else {
            setShowExcercise("false");
          }
        } else {
          setShowMessage(true);
        }
        setIsloading(false);
      })
      .catch((error) => { });
  };
  useEffect(() => {
    var condition = {
      payment_status: "paid",
    };
    if (params.orderId) {
      condition.order_id = params.orderId;
      OrderModal.userorder(condition)
        .then((res) => {
          console.clear();
          console.log(res.data.data);
          if (res?.data?.data[0]?.pack_cat_id === 3) {
            setUserPackage(res.data.data[0]);

            getUserInfo();
          } else {
            //console.log("cur = ", res?.data?.data[0])
            window.location.href = "/Orders";
          }
        })
        .catch((error) => { });
    }
  }, []);
  useEffect(() => {
    if(showMessage) {
      helper.sweetalert.normalToast("Great !!<br>You are now part of our health community.<br><br>Our Team will connect within 24 hours via email to understand your goals better.");
    }
  }, [showMessage])
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    console.clear();
    if (!userInfo?.id) {
      await OrderModal.createUserInfo(formData)
        .then((res) => {
          helper.sweetalert.toast("Your data updated successfully.");
          getUserInfo();
          //console.log(res.data)
        })
        .catch((error) => {
          helper.sweetalert.toast("Please try again later.", "warning");
          //console.log("error -> ", error)
        });
    } else {
      await OrderModal.updateUserInfo(userInfo?.id, formData)
        .then((res) => {
          helper.sweetalert.toast("Your data updated successfully.");
          getUserInfo();
          //console.log(res.data)
        })
        .catch((error) => {
          helper.sweetalert.toast("Please try again later.", "warning");
          //console.log("error -> ", error)
        });
    }
  };
  /* const toggleField = (id) => {
    const fieldElement = document.getElementById(id);
    if (fieldElement) {
      fieldElement.classList.toggle("d-none");
      if (fieldElement.classList.contains("d-none")) {
        const radioInputs = fieldElement.querySelectorAll(
          'input[type="radio"]'
        );
        radioInputs.forEach((input) => {
          input.checked = false;
        });
      }
    }
  }; */

  const handleRadioChange = (event) => {
    setShowExcercise(event.target.value);
  };

  return (
    <>
      <div>
        {isLoading ? (
          <div></div>
        ) : (
          <>
            <div
              className=""
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              {userData?.user_type === "user" ? (
                <>
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      marginBottom: "30px",
                    }}
                  >
                    <input
                      type="hidden"
                      name="order_id"
                      value={params.orderId}
                    />
                    <input
                      type="hidden"
                      name="package_id"
                      value={userpackage.package?.id}
                    />
                    <h5 style={{ marginBottom: "10px" }}>
                      Our Nutritionist needs to know a little bit about you and
                      your lifestyle. Please take a few minutes to answer the
                      following questions.
                    </h5>
                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div className="col-md-5">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          defaultValue={userData?.full_name}
                          placeholder="Full Name"
                          readOnly
                        />
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="age" className="form-label">
                          Age
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="age"
                          placeholder="Age"
                          defaultValue={userInfo?.age}
                          required
                        />
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="height" className="form-label">
                          Height
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="height"
                          placeholder="Height"
                          maxLength="40"
                          defaultValue={userInfo?.height}
                          required
                        />
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="weight" className="form-label">
                          Weight
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="weight"
                          placeholder="Weight"
                          step="any"
                          defaultValue={userInfo?.weight}
                          required
                        />
                      </div>
                    </div>

                    <h4 style={{ marginTop: "30px" }}>General Information</h4>
                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div className="col-md-12">
                        <label htmlFor="health_goal" className="form-label">
                          What are your specific health goals related to your
                          diet?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="health_goal"
                          placeholder="e.g., weight loss, muscle gain, improved energy levels"
                          defaultValue={userInfo?.health_goal}
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="dietry_prefrence"
                          className="form-label"
                        >
                          Dietary Preferences
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="dietry_prefrence"
                          placeholder="Veg, Non veg, Veg with eggs"
                          defaultValue={userInfo?.dietry_prefrence}
                        />
                      </div>
                      <div className="col-md-12">
                        <UserInfoToggleInput
                          inputLabel="Do you have any drug allergies?"
                          inputName="drug_allergies"
                          inputPlaceholder="If yes, please list:"
                          inputDefaultValue={userInfo?.drug_allergies}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <div className="col-md-12">
                        <UserInfoToggleInput
                          inputLabel="Do you currently take any medications?"
                          inputName="any_medication"
                          inputPlaceholder="If yes, please list:"
                          inputDefaultValue={userInfo?.any_medication}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <div className="col-md-12">
                        <UserInfoToggleInput
                          inputLabel="Do you currently take any vitamins or supplements?"
                          inputName="any_vitamins"
                          inputPlaceholder="If yes, please list:"
                          inputDefaultValue={userInfo?.any_vitamins}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <div className="col-md-12">
                        <UserInfoToggleInput
                          inputLabel="Do you use tobacco products? "
                          inputName="take_tobacco"
                          inputPlaceholder="If yes, how much?"
                          inputDefaultValue={userInfo?.take_tobacco}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <div className="col-md-12">
                        <UserInfoRadioInput
                          inputLabel="Do you drink alcohol?"
                          inputValues={[
                            "0 - 1 times/month",
                            "2 - 3 times/month",
                            "0 - 1 times/week",
                            "3 - 4 times/week",
                            "5+ times/week",
                          ]}
                          inputName="drink_alcohol"
                          inputDefaultValue={userInfo.drink_alcohol}
                          hideUsingCheckBox={true}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="health_history" className="form-label">
                          What is your health history?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="health_history"
                          placeholder="High blood pressure, Heart disease,Diabetes"
                          defaultValue={userInfo?.health_history}
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="average_stress_level"
                          className="form-label"
                        >
                          Rate your average stress level from 1 (no stress) to
                          10 (constant stress)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          style={{ width: "unset" }}
                          name="average_stress_level"
                          min="1"
                          max="10"
                          defaultValue={userInfo?.average_stress_level}
                        />
                      </div>
                    </div>

                    <h4 style={{ marginTop: "30px" }}>Weight History</h4>
                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div className="col-md-12">
                        <UserInfoToggleInput
                          inputLabel="Has your weight changed in the past year?"
                          inputName="weight_changed"
                          inputPlaceholder="How much weight have you gained or lost?"
                          inputDefaultValue={userInfo?.weight_changed}
                          infoId={userInfo?.id}
                        />
                      </div>

                      <div className="col-md-12">
                        <UserInfoToggleInput
                          inputLabel="Have you ever used weight loss medications "
                          inputName="weight_loss_medicine"
                          inputPlaceholder="what medications have you used"
                          inputDefaultValue={userInfo?.weight_loss_medicine}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <div className="col-md-12">
                        <UserInfoToggleInput
                          inputLabel="Have you had weight loss surgery? "
                          inputName="weight_loss_surgery"
                          inputPlaceholder="what type of surgery have you had"
                          inputDefaultValue={userInfo?.weight_loss_surgery}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <div className="col-md-12">
                        <UserInfoToggleInput
                          inputLabel="Have you tried any alternative therapies for weight loss"
                          inputName="Weight_loss_therapy"
                          inputPlaceholder=" i.e. acupuncture, nutritionist"
                          inputDefaultValue={userInfo?.Weight_loss_therapy}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <div className="col-md-12">
                        <UserInfoToggleInput
                          inputLabel="Have you ever purposefully restricted food intake and obtained what you or others felt was an extremely low or unhealthy weight?"
                          inputName="unhealthy_weight"
                          inputPlaceholder=""
                          inputDefaultValue={userInfo?.unhealthy_weight}
                          infoId={userInfo?.id}
                        />
                      </div>
                    </div>

                    <h4 style={{ marginTop: "30px" }}>Diet</h4>
                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div className="col-md-12">
                        <UserInfoRadioInput
                          inputLabel="How would you rate current eating habits?"
                          inputValues={["Excellent", "Good", "Fair", "Poor"]}
                          inputName="current_eating_habit"
                          inputDefaultValue={userInfo.current_eating_habit}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <h6 style={{ marginTop: "20px" }}>
                        <b>Eating Patterns</b>
                      </h6>
                      <div className="col-md-12">
                        <UserInfoRadioInput
                          inputLabel="How many meals do you usually eat per day?"
                          inputValues={["0-1", "2-3", "4+"]}
                          inputName="meal_per_day"
                          inputDefaultValue={userInfo.meal_per_day}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <div className="col-md-12">
                        <UserInfoRadioInput
                          inputLabel="How many snacks do you usually eat per day?"
                          inputValues={["0-1", "2-3", "4+"]}
                          inputName="snacks_per_day"
                          inputDefaultValue={userInfo.snacks_per_day}
                          infoId={userInfo?.id}
                        />
                      </div>
                      <div className="col-md-12">
                        <UserInfoRadioInput
                          inputLabel="How often do you skip meals?"
                          inputValues={[
                            "Never",
                            "Seldom",
                            "Sometimes",
                            "Often",
                            "Always",
                          ]}
                          inputName="skip_meal"
                          inputDefaultValue={userInfo.skip_meal}
                          infoId={userInfo?.id}
                        />
                      </div>

                      <div className="col-md-12">
                        <UserInfoToggleInput
                          inputLabel="Do you have any food intolerances or sensitivities?"
                          inputName="food_intolerances"
                          inputPlaceholder="If yes, Please explain?"
                          inputDefaultValue={userInfo?.food_intolerances}
                          infoId={userInfo?.id}
                        />
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="food_like" className="form-label">
                          What type of food do you like?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="food_like"
                          name="food_like"
                          placeholder=""
                          defaultValue={userInfo?.food_like}
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="food_dislike" className="form-label">
                          What are your food dislikes?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="food_dislike"
                          name="food_dislike"
                          placeholder=""
                          defaultValue={userInfo?.food_dislike}
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="water_per_day" className="form-label">
                          How much water do you drink per day ?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="19"
                          id="water_per_day"
                          name="water_per_day"
                          placeholder="in liters"
                          defaultValue={userInfo?.water_per_day}
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="caffine_per_day" className="form-label">
                          On average how many cups of caffeinated beverages do
                          you drink per day (tea, soda, coffee, or energy
                          drinks)?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="caffine_per_day"
                          maxLength="60"
                          name="caffine_per_day"
                          placeholder=""
                          defaultValue={userInfo?.caffine_per_day}
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="sugary_drink_per_day"
                          className="form-label"
                        >
                          On average how many cups of sugary drinks (soda,
                          sports drinks, juices)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="sugary_drink_per_day"
                          maxLength="60"
                          name="sugary_drink_per_day"
                          placeholder=""
                          defaultValue={userInfo?.sugary_drink_per_day}
                        />
                      </div>
                    </div>
                    <div
                      className="row pt-3 pb-3"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div className="col-md-12">
                        <label htmlFor="excercise_div" className="form-label">
                          Do you currently exercise?{" "}
                          <label>
                            <input
                              type="radio"
                              style={{ marginLeft: "15px" }}
                              checked={showExcercise === "true" ? true : false}
                              value="true"
                              onChange={handleRadioChange}
                            /> Yes
                          </label>
                          <label>
                            <input
                              type="radio"
                              style={{ marginLeft: "15px" }}
                              checked={showExcercise === "false" ? true : false}
                              value="false"
                              onChange={handleRadioChange}
                            /> No
                          </label>
                        </label>
                        <div
                          id="excercise_div"
                          className={`${showExcercise === "true" ? "" : "d-none"}`}
                        >
                          <div>
                            <label
                              htmlFor="type_of_excercise"
                              className="form-label"
                            >
                              What type of exercise?
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="type_of_excercise"
                              maxLength="200"
                              name="type_of_excercise"
                              placeholder=""
                              defaultValue={userInfo?.type_of_excercise}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="excercise_frequency"
                              className="form-label"
                            >
                              How many times
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="excercise_frequency"
                              maxLength="50"
                              name="excercise_frequency"
                              placeholder="days/week "
                              defaultValue={userInfo?.excercise_frequency}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="excercise_duration"
                              className="form-label"
                            >
                              How long?
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="excercise_duration"
                              maxLength="50"
                              name="excercise_duration"
                              placeholder="minutes/day"
                              defaultValue={userInfo?.excercise_duration}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="excercise_limitation"
                              className="form-label"
                            >
                              Do you have any exercise limitations?
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="excercise_limitation"
                              maxLength="150"
                              name="excercise_limitation"
                              placeholder=""
                              defaultValue={userInfo?.excercise_limitation}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="screen_time" className="form-label">
                          How much time do you spend on the screen
                          (computer/TV/Phone)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="screen_time"
                          maxLength="150"
                          name="screen_time"
                          placeholder="In Hours"
                          defaultValue={userInfo?.screen_time}
                        />
                      </div>
                    </div>
                    {userInfo?.id ? null : (
                      <>
                        <center>
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ marginTop: "20px" }}
                          >
                            Submit
                          </button>
                        </center>
                      </>
                    )}

                  </form>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      marginBottom: "30px",
                    }}
                  >
                    <h4 style={{ marginBottom: "10px" }}>Profile Info</h4>
                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div className="col-md-5">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userData?.full_name}
                          readOnly
                        />
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="age" className="form-label">
                          Age
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={userInfo?.age}
                          readOnly
                        />
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="height" className="form-label">
                          Height
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          step="any"
                          value={userInfo?.height}
                          readOnly
                        />
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="weight" className="form-label">
                          Weight
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          step="any"
                          value={userInfo?.weight}
                          readOnly
                        />
                      </div>
                    </div>

                    <h4 style={{ marginTop: "30px" }}>General Information</h4>
                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div className="col-md-12">
                        <label htmlFor="health_goal" className="form-label">
                          What are your specific health goals related to your
                          diet?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.health_goal}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="dietry_prefrence"
                          className="form-label"
                        >
                          Dietary Preferences
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.dietry_prefrence}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="drug_allergies" className="form-label">
                          Do you have any drug allergies?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.drug_allergies}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="any_medication" className="form-label">
                          Do you currently take any medications?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.any_medication}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="any_vitamins" className="form-label">
                          Do you currently take any vitamins or supplements?
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.any_vitamins}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="take_tobacco" className="form-label">
                          Do you use tobacco products?{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.take_tobacco}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="drink_alcohol" className="form-label">
                          Do you drink alcohol?{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.drink_alcohol}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="health_history" className="form-label">
                          What is your health history?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.health_history}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="average_stress_level"
                          className="form-label"
                        >
                          Rate your average stress level from 1 (no stress) to
                          10 (constant stress)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: "unset" }}
                          value={userInfo?.average_stress_level}
                          readOnly
                        />
                      </div>
                    </div>

                    <h4 style={{ marginTop: "30px" }}>Weight History</h4>
                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div className="col-md-12">
                        <label htmlFor="weight_changed" className="form-label">
                          Has your weight changed in the past year?{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.weight_changed}
                          readOnly
                        />
                      </div>

                      <div className="col-md-12">
                        <label
                          htmlFor="weight_loss_medicine"
                          className="form-label"
                        >
                          Have you ever used weight loss medications
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.weight_loss_medicine}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="weight_loss_surgery"
                          className="form-label"
                        >
                          Have you had weight loss surgery?{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control "
                          value={userInfo?.weight_loss_surgery}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="Weight_loss_therapy"
                          className="form-label"
                        >
                          Have you tried any alternative therapies for weight
                          loss
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.Weight_loss_therapy}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="unhealthy_weight"
                          className="form-label"
                        >
                          Have you ever purposefully restricted food intake and
                          obtained what you or others felt was an extremely low
                          or unhealthy weight?{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.unhealthy_weight}
                          readOnly
                        />
                      </div>
                    </div>

                    <h4 style={{ marginTop: "30px" }}>Diet</h4>
                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div className="col-md-12">
                        <label
                          htmlFor="current_eating_habit"
                          className="form-label"
                        >
                          How would you rate current eating habits?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.current_eating_habit}
                          readOnly
                        />
                      </div>
                      <h6 style={{ marginTop: "20px" }}>
                        <b>Eating Patterns</b>
                      </h6>
                      <div className="col-md-12">
                        <label htmlFor="meal_per_day" className="form-label">
                          How many meals do you usually eat per day?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.meal_per_day}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="snacks_per_day" className="form-label">
                          How many snacks do you usually eat per day?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.snacks_per_day}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="skip_meal" className="form-label">
                          How often do you skip meals?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.skip_meal}
                          readOnly
                        />
                      </div>

                      <div className="col-md-12">
                        <label
                          htmlFor="food_intolerances"
                          className="form-label"
                        >
                          Do you have any food intolerances or sensitivities?
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.food_intolerances}
                          readOnly
                        />
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="food_like" className="form-label">
                          What type of food do you like?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.food_like}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="food_dislike" className="form-label">
                          What are your food dislikes?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.food_dislike}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="water_per_day" className="form-label">
                          How much water do you drink per day ?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.water_per_day}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="caffine_per_day" className="form-label">
                          On average how many cups of caffeinated beverages do
                          you drink per day (tea, soda, coffee, or energy
                          drinks)?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.caffine_per_day}
                          readOnly
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="sugary_drink_per_day"
                          className="form-label"
                        >
                          On average how many cups of sugary drinks (soda,
                          sports drinks, juices)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.sugary_drink_per_day}
                          readOnly
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div className="col-md-12">
                        <label htmlFor="excercise_div" className="form-label">
                          Do you currently exercise?{" "}
                        </label>
                        <div id="excercise_div">
                          <div>
                            <label
                              htmlFor="type_of_excercise"
                              className="form-label"
                            >
                              What type of exercise?
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={userInfo?.type_of_excercise}
                              readOnly
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="excercise_frequency"
                              className="form-label"
                            >
                              How many times
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={userInfo?.excercise_frequency}
                              readOnly
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="excercise_duration"
                              className="form-label"
                            >
                              How long?
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={userInfo?.excercise_duration}
                              readOnly
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="excercise_limitation"
                              className="form-label"
                            >
                              Do you have any exercise limitations?
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={userInfo?.excercise_limitation}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="screen_time" className="form-label">
                          How much time do you spend on the screen
                          (computer/TV/Phone)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo?.screen_time}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserInfo;
