import React, { useEffect, useState, useRef } from "react";
import "../Aesets/css/style.css";
import "../Aesets/css/new.css";
import Auth from "../Model/Auth.Modal";
import { Link, useLocation } from "react-router-dom";
import AboutSection from "../Component/AboutSection";
import SectionGap from "../Component/SectionGap";

// import image25 from '../Aesets/images/Priyanka singh - 9.jpg';

// import gettrainingtoday from '../Aesets/images/gettrainingtoday.jpeg'
// import image12 from '../Aesets/images/Priyanka singh - 13.jpg';
// import Imagemain from '../Aesets/images/elitehealthimg.jpeg';
import womanpunching from "../Aesets/images/womanpunchingwithbox.d006a22a1c12df9f8e16.jpeg.jpg";
import homeimage1 from "../Aesets/images/homepageright.JPG";
import homeimage2 from "../Aesets/images/homepagecenter.JPG";
import homeimage3 from "../Aesets/images/homepageleft.JPG";
import exerciseman from "../Aesets/images/exercisemanhome.png";
import squartman from "../Aesets/images/squartmanhome.JPG";
import PackageSection from "../Component/PackageSection";

import { helper } from "../Lib/helper";
import HomeBanner from "../Component/HomeBannerSection";
import TestimonialSection from "../Component/TestimonialSection";
import appstore from "../Aesets/images/appstore.webp";
import playstore from "../Aesets/images/googleplay.webp";
import { Button, Card } from "react-bootstrap";
function Home() {
  const [trainerRegMessage, setTrainerRegMessage] = useState("");
  const [trainerMsgColor, setTrainerMsgColor] = useState("");
  const trainerRegister = (event) => {
    event.preventDefault();
    setTrainerRegMessage("");
    setTrainerMsgColor("");
    const form = new FormData(event.target);
    if (event.target.password.value === event.target.cnfPassword.value) {
      Auth.trainerRegistration(form)
        .then((res) => {
          setTrainerMsgColor("text-success");
          setTrainerRegMessage(res?.data?.messgae);
        })
        .catch((error) => {
          //console.log(error?.response?.data?.message)
          setTrainerMsgColor("text-danger");
          setTrainerRegMessage(error?.response?.data?.message);
        });
    } else {
      setTrainerRegMessage("Passwords not matched.");
      setTrainerMsgColor("text-danger");
    }
  };

  const showPassword = (inputId) => {
    //console.log("geggef")
    if (inputId) {
      const curType = document.getElementById(inputId).getAttribute("type");
      //console.log("current type = ", curType)
      if (curType === "password") {
        document.getElementById(inputId).setAttribute("type", "text");
        document.getElementById(inputId + "Span").innerHTML =
          '<i class="fas fa-eye-slash"></i>';
      } else {
        document.getElementById(inputId).setAttribute("type", "password");
        document.getElementById(inputId + "Span").innerHTML =
          '<i class="fas fa-eye"></i>';
      }
    }
  };
  const [trainerMob, setTrainerMob] = useState("");
  const handleRegMobChange = (e) => {
    if (helper.isNumber(e.target.value)) {
      setTrainerMob(e.target.value);
    } else if (e.target.value === "") {
      setTrainerMob(e.target.value);
    }
  };

  const userData = JSON.parse(sessionStorage.getItem("user_data"));

  const getNext15Min = (curTime) => {
    const timeParts = curTime.split(" ");
    const [hours, minutes] = timeParts[0].split(":");
    let newDate = new Date();
    newDate.setHours(parseInt(hours));
    newDate.setMinutes(parseInt(minutes));
    newDate.setMinutes(newDate.getMinutes() + 15);
    if (newDate.getHours() >= 12 && hours !== "12") {
      timeParts[1] = "pm";
    }
    const newHours = newDate.getHours() % 12 || 12;
    return `${newHours}:${newDate.getMinutes().toString().padStart(2, "0")} ${
      timeParts[1]
    }`;
  };

  const generateTime = () => {
    var startTime = "12:00 am";
    var endTime = "11:45 pm";
    var allTimes = [];
    var nextTime = startTime;
    allTimes.push(nextTime);
    while (nextTime !== endTime) {
      nextTime = getNext15Min(nextTime);
      allTimes.push(nextTime);
    }
    console.log("all times =>", allTimes);
  };

  const location = useLocation();
  const hashUrls = location.hash.substring(1);
  useEffect(() => {
    if (hashUrls) {
      const sectionToScroll = document.getElementById(hashUrls);

      if (sectionToScroll) {
        sectionToScroll.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hashUrls]);

  return (
    <div style={{ overflow: "hidden"}}>
      <HomeBanner />

    <div style={{display:"flex",justifyContent:"center"}} className="formorethen768">
    <Card className="text-center " style={{ width: '28rem',marginTop:"40px" }}>
        <Card.Body>
          <Card.Title>  Download Our App On:</Card.Title>
          <div className="mt-2">
            <a href="https://apps.apple.com/in/app/qr-staff/id6444002653">
              <img src={appstore} alt="apllie" style={{ height: "40px" }} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=in.qrstaff">
              <img
                src={playstore}
                alt=""
                style={{ height: "40px", marginLeft: "20px" }}
              />
            </a>
          </div>
        </Card.Body>
     
      </Card>
    </div>
    <div style={{display:"flex",justifyContent:"center"}} className="newresponsive">
    <Card className="text-center mt-3" style={{ width: '28rem' }}>
        <Card.Body>
          <Card.Title>  Download Our App On:</Card.Title>
          <div className="mt-2">
            <a href="https://apps.apple.com/in/app/qr-staff/id6444002653">
              <img src={appstore} alt="apllie" style={{ height: "40px" }} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=in.qrstaff">
              <img
                src={playstore}
                alt=""
                style={{ height: "40px", marginLeft: "20px" }}
              />
            </a>
          </div>
        </Card.Body>
     
      </Card>
    </div>

    
      <AboutSection />

      <section className="services-section ">
        <div className="container-fluid containr">
          <div className="row">
            <div className="col-lg-4">
              <div className="services-pic">
                <img src={homeimage3} style={{ borderRadius: "0px" }} alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="services-pic">
                <img src={homeimage2} style={{ borderRadius: "0px" }} alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="services-pic">
                <img src={homeimage1} style={{ borderRadius: "0px" }} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <TestimonialSection />
      <SectionGap />
      {/* <TrainingTodaySection /> */}

      <section className="services-section spad ">
        <div className="container-fluid containr">
          <div className="row">
            <div className="col-lg-6">
              <div className="services-pics">
                <div
                  className="banner-text"
                  style={{
                    backgroundColor: "black",
                    padding: "40px",
                    paddingTop: "60px",
                    borderRadius: "0px",
                  }}
                >
                  <h2 style={{ fontWeight: "500" }}>Get training today</h2>
                  <p style={{ fontSize: "15px" }}>
                    Ready to take your fitness to the next level? AR Elite Fit
                    can be a game changer for you. Our training programs are
                    designed to help you achieve your fitness goals, whether
                    you're a beginner or an advanced athlete. From personalized
                    workouts to expert guidance, our team of experienced
                    trainers is here to support you on your fitness journey.
                    Start your fitness game today and Sign up for training with
                    AR Elite Fit to unlock your full potential.
                  </p>
                  <a href="/Contact-Us" className="primary-btn banner-btn">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="services-pics">
                <img style={{ borderRadius: "0px" }} src={exerciseman} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {userData?.user_type !== "trainer" && (
        <>
          <section id="subscriptionDiv">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title mb-2  section-title  about-text">
                    <h2>Get Fit and Fabulous at Home</h2>
                    <h3>Choose your Elite Program</h3>
                  </div>
                </div>
              </div>
              <PackageSection />
            </div>
          </section>
          <SectionGap />
          <section
            id="join-our-team"
            style={{
              marginTop: "unset",
              paddingTop: "60px",
              paddingBottom: "60px",
            }}
          >
            <div className="container">
            <div className="row" style={{ maxWidth: "100%" }}>
              {/* <div className="col-lg-1"></div> */}

              <div className="col-lg-6">
                <div className="register-text" style={{ padding: "20px" }}>
                  <div className="section-title about-text">
                    <h3 style={{ fontWeight: "700" }}>
                      Join our AR ELITE FIT Trainer's Community
                    </h3>
                    <h4 style={{ color: "black" }}>Register now</h4>
                  </div>
                  <form className="register-form" onSubmit={trainerRegister}>
                    <div className={trainerMsgColor}>
                      <b>{trainerRegMessage}</b>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <label htmlFor="name">Full Name</label>
                        <input
                          type="text"
                          name="full_name"
                          className="inputBorderGreyColor"
                          placeholder="Full Name"
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="email">Your email address</label>
                        <input
                          type="email"
                          name="email"
                          className="inputBorderGreyColor"
                          placeholder="Email"
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="mobile">Mobile No*</label>
                        <input
                          type="text"
                          name="contact_no"
                          className="inputBorderGreyColor"
                          placeholder="Mobile No"
                          maxLength={10}
                          onChange={handleRegMobChange}
                          value={trainerMob}
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="password">Password*</label>
                        <div style={{ display: "flex", position: "relative" }}>
                          <input
                            type="password"
                            name="password"
                            className="inputBorderGreyColor"
                            placeholder="Password"
                            autocomplete="new-password"
                            id="regTrainerPassword"
                            required
                          />
                          <div
                            className="input-group-append"
                            style={{
                              height: "46px",
                              position: "absolute",
                              right: "0px",
                              padding: "2px",
                            }}
                          >
                            <span
                              className="input-group-text"
                              id="regTrainerPasswordSpan"
                              onClick={() => {
                                showPassword("regTrainerPassword");
                              }}
                              style={{ cursor: "pointer", height: "100%" }}
                            >
                              <i className="fas fa-eye"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="cnfPassword">Confirm Password*</label>
                        <div style={{ display: "flex", position: "relative" }}>
                          <input
                            type="password"
                            name="cnfPassword"
                            placeholder="Confirm Password"
                            autoComplete="off"
                            className="inputBorderGreyColor"
                            id="regTrainerCnfPassword"
                            required
                          />
                          <div
                            className="input-group-append"
                            style={{
                              height: "46px",
                              position: "absolute",
                              right: "0px",
                              padding: "2px",
                            }}
                          >
                            <span
                              className="input-group-text"
                              id="regTrainerCnfPasswordSpan"
                              onClick={() => {
                                showPassword("regTrainerCnfPassword");
                              }}
                              style={{ cursor: "pointer", height: "100%" }}
                            >
                              <i className="fas fa-eye"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="register-btn"
                      style={{ borderRadius: "8px" }}
                    >
                      Register
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="register-pic-container">
                  <img className="register-pic" src={squartman} alt="" />
                </div>
              </div>
            </div>

            </div>
          </section>
        </>
      )}

      <SectionGap />
    </div>
  );
}

export default Home;
