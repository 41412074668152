import React, { useEffect, useState } from 'react'
import Auth from '../Model/Auth.Modal'
import { useLocation, redirect, Link } from "react-router-dom";
// import { Card, ListGroup } from 'react-bootstrap';
const Profile = () => {
  const [userprofile, setUserprofile] = useState(false)
  const [isLoading, setIsloading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const getAccessToken = sessionStorage.getItem('token');
    Auth.userprofile().then((res) => {
      setUserprofile(res?.data?.data)
      if (res?.data?.data?.profile_img) {
        setSelectedImage(res?.data?.data?.profile_img)
      }
      //console.log(res.data);
      setIsloading(false)
    }).catch((error) => {
      // console.log("hereeee")
      redirect("/")
    })
  }, [])

  const [cardWidth, setCardWidth] = useState(window.innerWidth > 720 ? '100%' : '100%');

  useEffect(() => {
    const handleResize = () => {
      setCardWidth('90%');
      if (window.innerWidth > 720) {
        setCardWidth('90%')
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {isLoading ? (
        <div></div>
      ) : (
        <div className="row">
          
          <div>
            <div className='d-flex justify-content-center'>

              <div className='col-md-4' >

                <img style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '10px' }}
                  src={selectedImage || process.env.REACT_APP_DEFAULT_USER_IMAGE}
                  className="card-img-top1"
                  alt="..." />
              </div>

            </div>

            <form className="row g-3">

              <div className="col-md-4">
                <label htmlFor="full_name" className="form-label">Name</label>
                <input type="text" className="form-control" value= {userprofile.full_name} readOnly />
              </div>
              <div className="col-md-4">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="text" className="form-control" value={userprofile.email} readOnly />
              </div>
              <div className="col-md-4">
                <label htmlFor="contact_no" className="form-label">Contact No</label>
                <input type="text" className="form-control" value={userprofile.contact_no} readOnly />
              </div>
              <div className="col-md-4">
                <label htmlFor="user_age" className="form-label">Age</label>
                <input type="text" min={5} className="form-control" value= {userprofile.user_age} readOnly />
              </div>
              <div className="col-md-4">
                <label htmlFor="height" className="form-label">Height</label>
                <input type="text" step="any" className="form-control" value={userprofile?.height} readOnly />
              </div>
              <div className="col-md-4">
                <label htmlFor="body_fat" className="form-label">Body Fat</label>
                <input type="text" className="form-control" value={userprofile?.body_fat} readOnly />
              </div>
              <div className="col-md-4">
                <label htmlFor="exp_level" className="form-label">Body Fat</label>
                <select value={userprofile.exp_level} className='form-control' >
                  <option value="Beginer">Beginer</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advance">Advance</option>
                </select>
              </div>
              <div className="col-md-8">
                <label htmlFor="goals" className="form-label">Goals</label>
                <textarea className="form-control" value={userprofile?.goals} readOnly></textarea>
              </div>
              <div className='mt-4 flex' style={{ display: 'flex', justifyContent: 'center' }}>
                <Link to={"/Edit_profile"} className='btn btn-sm btn-success' >Edit</Link>
              </div>

            </form>
          </div>


        </div>




      )}
    </div>
  )
}

export default Profile
