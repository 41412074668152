import React, { useEffect, useState } from 'react'
import Packages from '../Model/Package.Modal'
import OrderModal from '../Model/Order.Modal'
import DataTable from 'react-data-table-component';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import { helper } from '../Lib/helper';
const PendingSessions = () => {
    const [isLoading, setIsloading] = useState(true);
    const [userpackage, setUserPackage] = useState(null);
    const [pendingId, setPendingId] = useState(null);
    const userData = JSON.parse(sessionStorage.getItem("user_data"));
    const currentTime = new Date();
    useEffect(() => {
        if (userpackage === null) {
            OrderModal.userPendingSessionList().then((res) => {
                setUserPackage(res?.data?.data)
                //console.clear();
                //console.log(res?.data?.data);
                /* let curpendingId = null;
                res?.data?.data?.map((session, index) => {
                    if (session?.status === "created" && curpendingId === null) {
                        curpendingId = session.id;
                    }
                })
                setPendingId(curpendingId); */
                setIsloading(false)
            }).catch((error) => {

            });
        }

    }, [])
    var columns = [
        {
            name: ' S.No.',

            cell: (row, index) => index + 1,
            width: '80px',

        },
        {
            name: ' Order Id',
            selector: (row) => row.order_id
        },
        {
            name: ' Batch Name',
            selector: (row) => row.batch_name
        },
        {
            name: ' Package Name',
            selector: (row) => row.package?.name
        },
        /* {
            name: ' Created On	',
            //selector: (row) => row.package?.created_at,
            cell: (row) => {
                return (
                    helper.convertDate(row.package?.created_at)
                );
            }
        }, */
        {
            name: 'Action	',
            cell: (row) => {
                const startTime = new Date();
                const endTime = new Date();
                const startSplit = row?.starts_at.split(' ');
                const startHourMinute = startSplit[0].split(':');
                const isPMStart = startSplit[1].toLowerCase() === 'pm';
                let startHour = parseInt(startHourMinute[0], 10);
                if (isPMStart && startHour !== 12) {
                    startHour += 12;
                }
                const startMinute = parseInt(startHourMinute[1], 10);

                const endSplit = row.end_at.split(' ');
                const endHourMinute = endSplit[0].split(':');
                const isPMEnd = endSplit[1].toLowerCase() === 'pm';
                let endHour = parseInt(endHourMinute[0], 10);
                if (isPMEnd && endHour !== 12) {
                    endHour += 12;
                }
                const endMinute = parseInt(endHourMinute[1], 10);

                startTime.setHours(startHour, startMinute, 0);
                endTime.setHours(endHour, endMinute, 0);

                const currentTime = new Date();

                var isDisabled = currentTime >= startTime && currentTime <= endTime;
                return (
                    <Row>
                        <Col id={row?.id}>
                            <a href={isDisabled ? `/join-Call/${row?.group_id}` : null }>
                                <button className={`btn btn-success btn-sm `} data-id={row?.id} disabled={!isDisabled}>
                                    {userData?.user_type === "trainer" ? "Start Call" : "Join Call"}
                                </button>
                            </a>
                        </Col>
                    </Row>
                );
            }
        },
    ]
    if(userData.user_type === "trainer") {
        var columns = [
            {
                name: ' S.No.',

                cell: (row, index) => index + 1,
                width: '80px',

            },
            {
                name: ' Group Id',
                selector: (row) => row.group_id
            },
            {
                name: ' Batch Name',
                selector: (row) => row.batch_name
            },
            {
                name: ' Package Name',
                selector: (row) => row.package?.name
            },
            {
                name: 'Action	',
                cell: (row) => {
                    const startTime = new Date();
                    const endTime = new Date();
                    const startSplit = row?.starts_at.split(' ');
                    const startHourMinute = startSplit[0].split(':');
                    const isPMStart = startSplit[1].toLowerCase() === 'pm';
                    let startHour = parseInt(startHourMinute[0], 10);
                    if (isPMStart && startHour !== 12) {
                        startHour += 12;
                    }
                    const startMinute = parseInt(startHourMinute[1], 10);

                    const endSplit = row?.end_at.split(' ');
                    const endHourMinute = endSplit[0].split(':');
                    const isPMEnd = endSplit[1].toLowerCase() === 'pm';
                    let endHour = parseInt(endHourMinute[0], 10);
                    if (isPMEnd && endHour !== 12) {
                        endHour += 12;
                    }
                    const endMinute = parseInt(endHourMinute[1], 10);

                    startTime.setHours(startHour, startMinute, 0);
                    endTime.setHours(endHour, endMinute, 0);

                    const currentTime = new Date();

                    var isDisabled = currentTime >= startTime && currentTime <= endTime;
                    //isDisabled = true;
                    return (
                        <Row>
                            <Col id={row?.id}>
                                <a href={isDisabled ? `/join-Call/${row?.group_id}` : null }>
                                    <button className={`btn btn-success btn-sm `} data-id={row?.id} disabled={!isDisabled}>
                                        {userData?.user_type === "trainer" ? "Start Call" : "Join Call"}
                                    </button>
                                </a>
                            </Col>
                        </Row>
                    );
                }
            },
        ]
    }
    const customStyles =
    {
        headCells: {
            style: {
                fontSize: '18px'

            },
        },
        cells: {
            style: {
                fontSize: '15px',


            }
        }

    };

    return (
        <>
            <div >
                {isLoading ? (<div></div>) : (
                    <>
                        {
                            <div>
                                <DataTable
                                    title='My Sessions'
                                    columns={columns}
                                    data={userpackage}
                                    pagination
                                    striped
                                    highlightOnHover
                                    subHeader
                                    customStyles={customStyles}


                                    subHeaderComponent={
                                        <input id='outline-basic'
                                            variant='outline'
                                            placeholder='Search Here'
                                            type='text'
                                            style={{ height: '40px', widows: '100%', paddingLeft: '10px' }}
                                        />
                                    }
                                />
                                {userpackage?.length === 0 &&
                                    <div className='text-center' style={{ marginTop: '2rem' }}>
                                        {userData?.user_type !== "trainer" &&
                                            <p>You have not purchased any packages yet.</p>
                                        }
                                        {userData?.user_type === "trainer" &&
                                            <p>You have not any orders yet.</p>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </>
                )}
            </div>

        </>
    )
}

export default PendingSessions
