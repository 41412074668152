import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import { useGlobalContext } from "../Lib/AppContext";
function PackageModelPriceCard({ packagePriceList, handlePricePicked }) {
    const handlePriceCheck = (e, price, days) => {
        handlePricePicked(e, price, days)
    }

    const { currency } = useGlobalContext();
    const [price, setPrice] = useState("");
    const [mrp, setMrp] = useState("");

    useEffect (() => {
        if(currency === "INR") {
            setPrice("₹ "+packagePriceList?.indian_price);
            setMrp("₹ "+packagePriceList?.indian_mrp)
        } else {
            setPrice("$ "+packagePriceList?.foreign_price);
            setMrp("$ "+packagePriceList?.foreign_mrp)
        }
    }, [currency])
    return (
        <>
            <div className="quiz_card_area col-4">
                <input className="quiz_checkbox" name="price_check" type="radio" value={packagePriceList?.id} onClick={(e) => {handlePriceCheck(e, packagePriceList?.indian_price, packagePriceList?.foreign_price, packagePriceList?.days)}} />
                <div className="single_quiz_card" style={{marginTop:'20px'}}>
                    <div className="quiz_card_title" style={{backgroundColor:'white'}}>
                        <span> {packagePriceList?.days} Days </span>
                        <hr />
                        <h6>{price}/-</h6>
                    </div>
                </div>
            </div>

        </>
    );
}

export default PackageModelPriceCard;