import React, { useEffect } from 'react'

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='container' style={{ marginBottom: '50px', padding:'5px 25px' }}>

            <h5 style={{ marginTop: '50px' }}>PRIVACY POLICY</h5>
            <div>
                <b>Last Updated: 26/04/2024</b>
                <p>This Privacy Policy describes how AR-Elite Fit ("we," "us," or "our") collects, uses, and discloses personal information when you visit our website or use our application ("Services"). By accessing or using our Services, you agree to the terms of this Privacy Policy.</p>
                <ol>
                    <li>
                        <div>Information We Collect</div>
                        <div>We collect personal information from you when you interact with our Services, including when you register for an account, make a purchase, participate in online sessions, or sign up for nutrition plans. The types of personal information we may collect include:</div>
                        <ul>
                            <li>Contact information (such as name, email address, phone number)</li>
                            <li>Payment information (such as credit card details)</li>
                            <li>Health and fitness information (such as height, weight, fitness goals)</li>
                            <li>Usage data (such as IP address, device information, browser type)</li>
                            <li>Communications preferences</li>
                        </ul>
                    </li>
                    <li>
                        <div>How We Use Your Information</div>
                        <div>We use the personal information we collect for the following purposes:</div>
                        <ul>
                            <li>To provide and personalise our Services, including online sessions and nutrition plans</li>
                            <li>To process transactions and payments</li>
                            <li>To communicate with you about your account, orders, and inquiries</li>
                            <li>To send you marketing and promotional materials (with your consent)</li>
                            <li>To improve our Services and develop new features</li>
                            <li>To comply with legal obligations and enforce our policies</li>
                        </ul>
                    </li>
                    <li>
                        <div>Sharing of Information</div>
                        <div>We may share your personal information with third parties for the following purposes:</div>
                        <ul>
                            <li>With service providers who assist us in providing our Services (such as payment processors, hosting providers)</li>
                            <li>With third-party partners who offer complementary products or services</li>
                            <li>With law enforcement or regulatory authorities when required by law or to protect our rights</li>
                            <li>We do not sell or rent your personal information to third parties for their own marketing purposes</li>
                        </ul>
                    </li>
                    <li>
                        <div>Data Retention</div>
                        <div>We retain your personal information for as long as necessary to fulfils the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.</div>
                    </li>
                    <li>
                        <div>Your Choices</div>
                        <div>You may update your account information and communication preferences by accessing your account settings. You may also unsubscribe from marketing emails by following the instructions provided in the email.</div>
                    </li>
                    <li>
                        <div>Security</div>
                        <div>We implement reasonable security measures to protect your personal information from unauthorised access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.</div>
                    </li>
                    <li>
                        <div>Children's Privacy</div>
                        <div>Our Services are not intended for children under the age of 18. We do not knowingly collect personal information from children under the age of 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us to request deletion.</div>
                    </li>
                    <li>
                        <div>Changes to this Privacy Policy</div>
                        <div>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website or app and updating the "Last Updated" date.</div>
                    </li>
                    <li>
                        <div>Contact Us</div>
                        <div>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <b>support@arelitefit.com</b></div>
                    </li>
                </ol>
            </div>
        </div>
    )
}
