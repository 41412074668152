import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;
//console.log("baseUrl => ", baseUrl);
const axiosInstance = axios.create({
    
    baseURL: baseUrl,
    //   headers: { 'X-Requested-With': 'XMLHttpRequest' },
    // withCredentials: true,
    transformRequest: function (data, headers) {
        const token = sessionStorage.getItem('accessToken');
        if (token) {
            headers.Authorization = "Bearer " + token;
        }
        return data;
    },
});
export default axiosInstance;   