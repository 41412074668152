import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../Aesets/img/icon/icon-1.png'
import icon2 from '../Aesets/img/icon/icon-2.png'
import icon3 from '../Aesets/img/icon/icon-3.png'
import AuthModal from '../Model/Auth.Modal'

const ContactUs = () => {
    const [contactMsg, setContactMsg] = useState("");
    const [contactMsgColor, setContactMsgColor] = useState("");
    const [disableSend, setDisableSend] = useState(false)
    const sendContactMessage = (event) => {
        event.preventDefault();
        setContactMsg("");
        setContactMsgColor("");
        setDisableSend(true);
        const form = new FormData(event.target);
        AuthModal.ContactUs(form).then((res) => {
            setContactMsgColor("text-success");
            setContactMsg(res?.data?.message);
            console.log(res?.data?.message)
        }).catch((error) => {
            setDisableSend(false);
            //console.log(error?.response?.data?.message)
            setContactMsgColor("text-danger")
            setContactMsg(error?.response?.data?.message);
        })
    }
    return (
        <>
            <section className="breadcrumb-section set-bg  border-0-radius">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-text">
                                <h2>Contact</h2>
                                <div className="breadcrumb-option">
                                    <Link to='/'><i className="fas fa-home"></i> Home</Link>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-chevron-compact-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />
                                    </svg>
                                    <span>Contact</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14009.089234023779!2d77.4257472!3d28.6215997!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef549c404cdd%3A0xc72e22b90c3c8991!2sMahagun%20Mywoods!5e0!3m2!1sen!2sin!4v1708087845032!5m2!1sen!2sin" height="612" style={{ border: "0" }} allowfullscreen=""></iframe>
                
            </div>
            <section className="contact-section spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="contact-info">
                                <h4>Contact Us</h4>
                                <div className="contact-address">
                                    <div className="ca-widget">
                                        <div className="cw-icon">
                                            <img src={icon1} alt="" />
                                        </div>
                                        <div className="cw-text">
                                            <h5 style={{color:'black'}}>Our Location</h5>
                                            <p>Mahagun Mywoods, Greater Noida west</p>
                                        </div>
                                    </div>
                                    <div className="ca-widget">
                                        <div className="cw-icon">
                                            <img src={icon2} alt="" />
                                        </div>
                                        <div className="cw-text">
                                            <h5 style={{color:'black'}}>Phone:</h5>
                                            <p>+91 9818577384</p>
                                        </div>
                                    </div>
                                    <div className="ca-widget">
                                        <div className="cw-icon">
                                            <img src={icon3} alt="" />
                                        </div>
                                        <div className="cw-text">
                                            <h5 style={{color:'black'}}>Mail</h5>
                                            <p><a style={{ color: 'black' }} href="mailto:support@arelitefit.com">support@arelitefit.com</a> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="contact-form">
                                <h4>Still have Queries ? Talk to our Expert</h4>
                                <form onSubmit={sendContactMessage}>
                                    <div className={contactMsgColor}><b>{contactMsg}</b></div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="text" className='inputBorderGreyColor' required placeholder="Your name" name='name' />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="email" className='inputBorderGreyColor' required placeholder="Your email" name='email' />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" className='inputBorderGreyColor' maxLength={"15"} required placeholder="Contact No." name='contact_no' />
                                        </div>
                                        <div className="col-lg-6">
                                            <textarea required placeholder="Your messages" className='inputBorderGreyColor' name='message'></textarea>
                                            <button style={{ borderRadius: '8px', backgroundColor: 'red' }} type="submit" disabled={disableSend}>Send enquiry</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs
