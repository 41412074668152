import React, { useEffect, useState } from "react";
import SectionGap from "../Component/SectionGap";
import PackageSection from "../Component/PackageSection";
import { useNavigate } from "react-router-dom";
import Blogs from "../Model/Blog.Modal";
import { Card, Modal, Button, Dropdown, Col, Row } from "react-bootstrap";
import "../Aesets/css/new.css";
function Blog() {
  let navigate = useNavigate();

  useEffect(() => {
    const userDetail = JSON.parse(sessionStorage.getItem("user_data"));
    if (userDetail?.user_type === "trainer") {
      navigate("/home");
    }
  }, []);

  const [blogsList, setBlogsList] = useState([]);
  const getAllPackage = () => {
    Blogs.ALLBlogs()
      .then((res) => {
        //console.clear();
        //console.log("packages -> ", res?.data?.data)
        setBlogsList(res?.data?.data);
      })
      .catch((error) => {
        //console.log("error => ", error)
      });
  };

  console.log(blogsList, "blogsListl");
  useEffect(() => {
    getAllPackage();
  }, []);

  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const toggleExpand = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  const handleShow = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  function extractFirstFiveLines(description) {
    // Split the description by closing tags '</p>' or '</div>'
    let splitDescription = description.split(/<\/(p|div)>/);
    let lines = [];

    // Loop through the split description to extract the text content
    for (let i = 0; i < splitDescription.length; i++) {
      let text = splitDescription[i].replace(/<[^>]*>?/gm, ""); // Remove HTML tags
      text = text.trim(); // Trim any leading or trailing whitespace
      if (text !== "") {
        lines.push(text); // Add non-empty lines to the result
      }
      if (lines.length >= 1) {
        break; // If we have 5 lines, exit the loop
      }
    }

    // Join the first five lines and return
    return lines.join("\n");
  }

  return (
    <>
      <section id="subscriptionDiv" className="mt-2">
        {/* <PackageSection /> */}
        <div
  style={{
    width: "80%",
    margin: "0 auto", // Centering the div itself
    // backgroundColor: "red"
  }}
>
  <Row className="mb-4 mt-2" >
    {blogsList.map((packageData, index) => (
      <Col key={index} md="4 mt-2">
        <Card className="blog-card" style={{ height: "100%"}}>
          <Card.Img
            variant="top"
            src={packageData?.media_url}
            style={{ objectFit: "cover" }} // Ensure image covers the entire card
          />
          <Card.Body style={{ display: "flex", flexDirection: "column",}}>
            <Card.Title style={{ fontWeight: "bold" }}>
              {packageData?.title}
            </Card.Title>
            <Card.Text style={{ flex: 1 }}>
              {/* Render truncated or full description based on the 'isExpanded' state */}
              <span
                style={{ fontSize: "14px", color: "black" }}
                dangerouslySetInnerHTML={{
                  __html: extractFirstFiveLines(packageData.description)
                }}
              />
            </Card.Text>
            <div style={{ marginTop: "auto" }}>
              <button
                onClick={() => handleShow(packageData)}
                className="primary-btn"
                style={{ borderRadius: "8px", borderStyle: "none" }}
              >
                View More
              </button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    ))}
  </Row>
</div>


        <Modal show={showModal} onHide={handleClose} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Blog Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Card>
              <Card.Img
                variant="top"
                src={modalContent?.media_url}
                height={250}
                width={50}
              />
              <Card.Body>
                <Card.Title style={{ fontWeight: "bold" }}>
                  {modalContent?.title}
                </Card.Title>
                <Card.Text>
                  {/* Render truncated or full description based on the 'isExpanded' state */}
                  <span
                    style={{ fontSize: "14px", color: "black" }}
                    dangerouslySetInnerHTML={{
                      __html: modalContent.description,
                    }}
                  />
                  {/* Render Show More button if description has more than 5 lines */}
                </Card.Text>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  );
}
export default Blog;
