import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
// import { CDBIcon } from 'cdbreact';
//import logo from '../../Aesets/img/1logo.png';
import logo from '../../Aesets/img/new_logo6.JPG';
import Auth from '../../Model/Auth.Modal';
function Header() {
  const [userprofile, setUserprofile] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    if (sessionStorage.getItem("user_data")) {
      const user_data = JSON.parse(sessionStorage.getItem("user_data"));
      setUserprofile(user_data);
      if (user_data?.profile_img) {
        setSelectedImage(user_data?.profile_img)
      }
    } else {
      window.location.assign("/");
    }
  }, [sessionStorage.getItem("user_data")])

  return (
    <>
      <Navbar className='border-0-radius' bg='dark' variant="dark" text="light">
        <Container >
          <Navbar.Brand as={Link} to="/" style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <img
              src={logo}
              height="45"
              style={{borderRadius:'50%'}}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>

          <Link to={'/Profile'}>
            <div style={{ position: 'relative', float: 'right' }}>
              <span style={{ fontSize: '20px', float: 'right' }} className='text-light text-capitalize'>{userprofile.full_name}</span>

              <img width={30} height={30} style={{ marginRight: '10px', position: 'relative', float: 'right' }} src={selectedImage || process.env.REACT_APP_DEFAULT_USER_IMAGE} alt="" />
            </div>
          </Link>
        </Container>
      </Navbar>

    </>
  );
}

export default Header;