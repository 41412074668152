import React from "react";

function SectionGap({trainerData}) {

    return (
        <>
            <div style={{height:'1rem'}}></div>
        </>
    );
}

export default SectionGap;