import React, { useEffect, useState, useRef } from 'react'
import Auth from '../Model/Auth.Modal'
import { useLocation, redirect, Link } from "react-router-dom";
import { helper } from '../Lib/helper';


// import { Card, ListGroup } from 'react-bootstrap';
const Profile = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsloading] = useState(true);
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    contact_no: '',
    //password: '',
    gender: '',
    user_age: 0,
    height: 0,
    body_fat: '',
    exp_level: '',
    medical_condition: '',
    goals: '',
  });

  const allDefErrors = {
    full_name: '',
    gender: '',
    email: '',
    contact_no: '',
    //password: '',
    user_age: '',
    height: '',
    body_fat: '',
    exp_level: '',
    medical_condition: '',
    goals: '',
  }

  const [fieldErrors, setFieldErrors] = useState(allDefErrors);
  useEffect(() => {
    const getAccessToken = sessionStorage.getItem('token');
    Auth.userprofile().then((res) => {
      const userProfileData = res.data.data;
      setFormData({
        full_name: userProfileData.full_name,
        email: userProfileData.email,
        contact_no: userProfileData.contact_no,
        //password: userProfileData.password,
        gender: userProfileData.gender,
        user_age: userProfileData.user_age,
        height: userProfileData.height,
        body_fat: userProfileData.body_fat,
        exp_level: userProfileData.exp_level,
        medical_condition: userProfileData.medical_condition,
        goals: userProfileData.goals,
      });
      if (userProfileData?.profile_img) {
        setSelectedImage(userProfileData?.profile_img)
      }
      setIsloading(false)
    }).catch((error) => {
      redirect("/")
    })
  }, [])

  const handleInputChange = (event) => {
    if (event?.target?.type !== "file") {
      /* setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      }); */
      if (event.target.name === "contact_no") {
        if (/^\+[0-9+]+$/.test(event.target.value) || /^[0-9+]+$/.test(event.target.value)) {
          setFormData({
            ...formData,
            [event.target.name]: event.target.value,
          });
        } else if (event.target.value === "") {
          setFormData({
            ...formData,
            [event.target.name]: event.target.value,
          });
        }
      } else {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      }
    }
  };
  const fileInputRef = useRef(null);
  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);

      const form = new FormData();
      form.append("profile_img", file);
      Auth.updateProfile(form).then((response) => {
        setSelectedImage(response?.data?.data?.profile_img);
        helper.sweetalert.toast("Profile Image Updated");
      }).catch((error) => {
        console.log(error?.response);
      });
    }
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");
    const form = new FormData();
    for (const fieldName in formData) {
      form.append(fieldName, formData[fieldName]);
    }
    setIsBtnDisabled(true);
    Auth.updateProfile(form)
      .then((response) => {
        sessionStorage.setItem('user_data', JSON.stringify(response?.data?.data));
        helper.sweetalert.toast("Profile Updated");
        setIsBtnDisabled(false);
      }).catch((error) => {
        setIsBtnDisabled(false);
        console.log(error?.response);
        setErrorMessage(error?.response?.data?.message)
      });
  };

  return (
    <div>
      {isLoading ? (
        <div></div>
      ) : (
        <div>
          <div className='d-flex justify-content-center'>

            <div className='col-md-4' >

              <img style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '10px' }}
                src={selectedImage || process.env.REACT_APP_DEFAULT_USER_IMAGE}
                className="card-img-top1"
                alt="..."
                onClick={handleImageClick}


              />
              {/* <button style={{border:'none',backgroundColor:'white'}} onClick={handleImageClick}><i className="fa fa-edit"/></button> */}

              <input type='file' ref={fileInputRef} accept="image/*"
                style={{ display: 'none' }} onChange={handleImageChange} />
            </div>

            {/* <Editimage/> */}

          </div>

          <form className="row g-3" onSubmit={handleSubmit}>

            <div className="col-md-4">
              <label htmlFor="full_name" className="form-label">Name</label>

              <input type="text" className="form-control" id="full_name" name='full_name' onChange={handleInputChange} value={formData?.full_name} placeholder='Full Name' required />
              {fieldErrors.full_name && <div className="error-message">{fieldErrors.full_name}</div>}
            </div>
            <div className="col-md-4">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="text" className="form-control" id="email" name='email' onChange={handleInputChange} value={formData?.email} placeholder='Email' required />
              {fieldErrors.email && <div className="error-message">{fieldErrors.email}</div>}
            </div>
            <div className="col-md-4">
              <label htmlFor="contact_no" className="form-label">Contact No</label>
              <input type="text" className="form-control" id="contact_no" name='contact_no' onChange={handleInputChange} value={formData?.contact_no} placeholder='Contact No.' required />
              {fieldErrors.contact_no && <div className="error-message">{fieldErrors.contact_no}</div>}
            </div>
            <div className="col-md-4">
              <label htmlFor="user_age" className="form-label">Age</label>
              <input type="text" min={5} className="form-control" id="user_age" name='user_age' onChange={handleInputChange} value={formData?.user_age} placeholder='Age' required />
              {fieldErrors.user_age && <div className="error-message">{fieldErrors.user_age}</div>}
            </div>
            <div className="col-md-4">
              <label htmlFor="height" className="form-label">Height</label>
              <input type="text" min="1" step="any" className="form-control" id="height" name='height' onChange={handleInputChange} value={formData?.height} placeholder='Height' required />
              {fieldErrors.height && <div className="error-message">{fieldErrors.height}</div>}
            </div>
            <div className="col-md-4">
              <label htmlFor="body_fat" className="form-label">Body Fat</label>
              <input type="text" className="form-control" id="body_fat" name='body_fat' onChange={handleInputChange} value={formData?.body_fat} placeholder='Body Fat' required />
              {fieldErrors.body_fat && <div className="error-message">{fieldErrors.body_fat}</div>}
            </div>
            <div className="col-md-4">
              <label htmlFor="exp_level" className="form-label">Body Fat</label>
              <select value={formData.exp_level} className='form-control' name='exp_level' onChange={handleInputChange}>
                <option value="Beginer">Beginer</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advance">Advance</option>
              </select>
              {fieldErrors.body_fat && <div className="error-message">{fieldErrors.body_fat}</div>}
            </div>
            <div className="col-md-8">
              <label htmlFor="goals" className="form-label">Goals</label>
              <textarea className="form-control" id="goals" name='goals' onChange={handleInputChange} value={formData?.goals} placeholder='Goals' required></textarea>
              {fieldErrors.goals && <div className="error-message">{fieldErrors.goals}</div>}
            </div>
            <div className='mt-4 flex' style={{ display: 'flex', justifyContent: 'center' }}>
              {/* <Link to={"/profile"}> */}
              <button type='submit' className='btn btn-sm btn-success' disabled={isBtnDisabled} >Update</button>
              {/* </Link> */}

            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default Profile
