import Auth from "../Model/Auth.Modal";
import { Link } from "react-router-dom";
import bannerImage from '../Aesets/images/homepage.jpg';
import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const HomeBanner = () => {

  const [bannerImageAr, setBannerImageAr] = useState(null);
  const [bannerImg, setBannerImg] = useState(null);
  const [bannerImgPos, setBannerImgPos] = useState(0);
  const [leftIconSize, setLeftIconSize] = useState(40);
  const [rightIconSize, setRightIconSize] = useState(40);
  const [isLoading, setIsLoading] = useState(true);

  const getNextBanner = () => {
    setBannerImgPos((prevPos) => (prevPos + 1) % bannerImageAr?.length);
    setBannerImg(bannerImageAr[bannerImgPos]);
  };

  const getPrevBanner = () => {
    setBannerImgPos((prevPos) => (prevPos - 1 + bannerImageAr.length) % bannerImageAr?.length);
    setBannerImg(bannerImageAr[bannerImgPos]);
  };
  useEffect(() => {
    const intervalId = setInterval(getNextBanner, 5000);
    return () => clearInterval(intervalId);
  }, [bannerImageAr, bannerImgPos]);

  const getBanners = () => {
    Auth.GetBanners().then((res) => {
      var bannerTmp = [];
      res?.data?.data?.map((bannerData, index) => {
        if (index === 0) {
          setBannerImg(bannerData?.link);
        }
        bannerTmp.push(bannerData?.link)
      });
      setIsLoading(false);
      setBannerImageAr(bannerTmp);
    }).catch((error) => {
      console.log("thome error", error.message);
    })
  } 
  useEffect(() => {
    getBanners();
  }, []);

  return (
    <>
      {!isLoading && (
        <>
          <section
            className="hero-section2 set-bg border-0-radius formorethen768"
            style={{

              backgroundImage: `url(${bannerImg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="container" style={{ height: '100%' }}>
              <div className="row" style={{ height: '100%' }}>
                <div className="col-12">
                  <div className="hero-text2 d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                    {/* <h1 style={{ color: 'white' }}>WELCOME TO YOUR VIRTUAL GYM</h1>
               <p style={{ color: 'white' }}>Get your Transformation with our home workout and become a better version of yourself.</p> */}
                    <Link to="/Subscription" className="primary-btn" style={{  top: '20px' }}>
                      Join Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                style={{ position: 'absolute', left: '10px', top: '20%', transform: 'translateY(-50%)' }}
                onMouseOver={() => setLeftIconSize(60)}
                onMouseLeave={() => setLeftIconSize(40)}
              >
                <FaChevronLeft onClick={getPrevBanner} size={leftIconSize} />
              </div>
              <div
                style={{ position: 'absolute', right: '10px', top: '20%', transform: 'translateY(-50%)' }}
                onMouseOver={() => setRightIconSize(60)}
                onMouseLeave={() => setRightIconSize(40)}
              >
                <FaChevronRight onClick={getNextBanner} size={rightIconSize} />
              </div>
            </div>
          </section>
          <section
            className="hero-section set-bg border-0-radius  newresponsive"
            style={{
              minHeight: '100vh',
              backgroundImage: `url(${bannerImg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="container" style={{ height: '100%' }}>
              <div className="row" style={{ height: '100%' }}>
                <div className="col-12">
                  <div className="hero-text d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                    {/* <h1 style={{ color: 'white' }}>WELCOME TO YOUR VIRTUAL GYM</h1>
                <p style={{ color: 'white' }}>Get your Transformation with our home workout and become a better version of yourself.</p> */}
                    <Link to="/Subscription" className="primary-btn" style={{ position: 'absolute', bottom: '20px' }}>
                      Join Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}
                onMouseOver={() => setLeftIconSize(60)}
                onMouseLeave={() => setLeftIconSize(40)}
              >
                <FaChevronLeft onClick={getPrevBanner} size={leftIconSize} />
              </div>
              <div
                style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                onMouseOver={() => setRightIconSize(60)}
                onMouseLeave={() => setRightIconSize(40)}
              >
                <FaChevronRight onClick={getNextBanner} size={rightIconSize} />
              </div>
            </div>
          </section>
        </>
      )}

    </>

  );
};

export default HomeBanner;

