import { createContext, useContext, useState, ReactNode, useEffect } from "react";


const GlobalContext = createContext({
  currency: '',
  setCurrency: () => '',
});

export const GlobalContextProvider = ({ children }) => {
  const [currency, setCurrency] = useState(localStorage.getItem("currency") ?? "INR");
  useEffect(() => {
    if(currency === "INR" || currency === "USD") {
      localStorage.setItem("currency", currency);
    }
  }, [currency])
  return (
    <GlobalContext.Provider value={{ currency, setCurrency }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
