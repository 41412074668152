import axiosInstance from "../Lib/axiosInstance";
const Auth =
{
    async Registration(data = []) {
        return await axiosInstance.post("auth/registration/", data);
    },
    async trainerRegistration(data = []) {
        return await axiosInstance.post("auth/trainer_registeration/", data);
    },
    async login(data = []) {
        return await axiosInstance.post("auth/otp_login", data);
    },
    async otp(otp = []) {
        return await axiosInstance.post("auth/verify_otp", otp);
    },
    async loginbyemail(email = []) {
        return await axiosInstance.post("auth/login/", email);
    },
    async userprofile(data){
        return await axiosInstance.get("users/profile" , data);
    },
    async updateProfile(data){
        return await axiosInstance.post("users/profile" , data);
    },

    async Trainerlist(data ){
        return await axiosInstance.get("users/trainers" , data)
    },

    async ContactUs(data = []) {
        return await axiosInstance.post("contact_us", data);
    },

    async SendEmailOtp(data = []) {
        return await axiosInstance.post("auth/generate_reset_otp", data);
    },

    async VerifyEmailOtp(data = []) {
        return await axiosInstance.post("auth/verify_otp", data);
    },
    async ResetEmailPassword(data = []) {
        return await axiosInstance.post("auth/reset_password", data);
    },

    async GetTestimonials() {
        return await axiosInstance.get("home/get_testimonials");
    },

    async GetHome() {
        return await axiosInstance.get("home?platformType=Web");
    },
    async GetBanners() {
        return await axiosInstance.get("home/get_banners?platformType=Web");
    },
}
export default Auth