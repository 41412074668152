import React from 'react';
 
import { Outlet } from 'react-router';
import Sidenav from "../dashboard/Component/Sidebar";
import Topnav from "../dashboard/Component/Header";
import Footer from '../Layout/Footer';
function  WithNav()  {
  return (
    <>
      <div>
        <Topnav />
        <div className="container-fluid" >
          <div className='row mb-3'>
            <div className="col-4 col-sm-3" style={{ padding: '0' }} >
              <Sidenav />
            </div>
            <div className="col-8 col-sm-9">
              <Outlet />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default WithNav;