import axiosInstance from "../Lib/axiosInstance";
import { useParams } from "react-router-dom";

const Blogs = {
  async ALLBlogs(data) {
    return await axiosInstance.get("blogs", data);
  },
};

export default Blogs;
