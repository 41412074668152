import React, { useEffect, useState, useRef } from 'react';
import { Card, Modal } from 'react-bootstrap';
import Auth from '../Model/Auth.Modal';
import OrderModal from '../Model/Order.Modal';
import Packages from '../Model/Package.Modal';
import AuthModal from '../Model/Auth.Modal';
import PackageSectionCard from './Package';
import PackageModelPriceCard from './PackageModelPriceCard';
import { useGlobalContext } from '../Lib/AppContext';
import { Link, useLocation } from "react-router-dom";
import { helper } from '../Lib/helper';
function PackageSection() {
    const [packagess, setPackages] = useState([]);
    const [currentPackage, setCurrentPackage] = useState(null);
    const [show, setShow] = useState(false);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [showpakage, setShowpackage] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [Healthissue, setHealthissue] = useState(false);
    const [TrainerList, setTrainerList] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState({});
    const [selectedValue, setSelectedValue] = useState('');
    const [curPrice, setCurPrice] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const { currency } = useGlobalContext();
    const [showTrainer, setShowTrainer] = useState("d-none");
    const [batchList, setBatchList] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState(null);

    const handleBatchChange = (e) => {
        //console.log("batch = ", e?.target?.value)
        setSelectedBatch(e?.target?.value)
    }

    const getAllPackage = () => {
        Packages.ALlpackage().then((res) => {
            //console.clear();
            //console.log("packages -> ", res?.data?.data)
            setPackages(res?.data?.data);
        }).catch((error) => {
            //console.log("error => ", error)
        })
    }

    const getAllHealthIssue = () => {
        Packages.Healthissue().then((res) => {
            setHealthissue(res.data.data);
        }).catch((error) => {
            //console.log("error => ", error)
        })
    }

    const getAllTrainers = () => {
        AuthModal.Trainerlist().then((res) => {
            setTrainerList(res.data.data);
        }).catch((error) => {
        })
    }

    useEffect(() => {
        getAllPackage();
        getAllHealthIssue();
        getAllTrainers();
    }, [])

    const handleClose = () => {
        setIsChecked(false);
        setIsButtonActive(false);
        setCurrentPackage(null);
        setShow(false);
        setShowAlert(false);
        setTotalPrice(0);
    }

    const handleShow = (index = null) => {
        console.clear();
        console.log("current pack ->", index?.pack_cat_id);
        setBatchList(index?.package_batches)
        setCurrentPackage(index);
        setShow(true)
        if (index?.pack_cat_id === 2) {
            setShowTrainer("");
        }
    };

    const handleCheckboxChange = (e, price, foreign_price, days) => {
        setCurPrice({
            id: e.target.value,
            price: price,
            foreign_price: foreign_price,
            days: days
        })
        if (currency === "INR") {
            setTotalPrice(price);
        } else {
            setTotalPrice(foreign_price);
        }

        setIsChecked(true);
        setIsButtonActive(true);
    };

    const handleClosepackage = () => {
        setDisclaimer(false)
        if (currency === "INR") {
            setTotalPrice(curPrice?.price);
        } else {
            setTotalPrice(curPrice?.foreign_price);
        }
        setShowTrainer("d-none");
        setShowpackage(false);
        setSelectedOptions([]);
        setSelectedValue("");
        setSelectedTrainer({})
        setIsButtonActive(true);
    }

    const handlePackage = () => {
        Auth.userprofile().then((res) => {
            if (res?.data?.is_error === false) {
                if (currentPackage?.pack_cat_id === 4) {
                    Payment();
                } else {
                    if (currentPackage?.pack_cat_id === 2) {
                        setShowTrainer("");
                    } else {
                        setShowTrainer("d-none");
                    }
                    setShowpackage(true);
                    setIsButtonActive(false);
                }
            }
        }).catch((error) => {
            setShowAlert(true);
            //console.log("login error => ")
        })
    };

    const onOptionSelected = (e, issueData) => {
        const updatedOptions = [...selectedOptions];
        if (e.target.checked) {
            if (currency === "INR") {
                setTotalPrice(totalPrice + issueData?.price);
            } else {
                setTotalPrice(totalPrice + issueData?.foreign_price);
            }

            updatedOptions.push({ id: issueData?.id, name: issueData?.name, price: issueData?.price, foreign_price: issueData?.foreign_price, type: "Health Issue" });
        } else {
            const indexToRemove = updatedOptions.findIndex((data) => data.id === issueData?.id);
            if (indexToRemove !== -1) {
                if (currency === "INR") {
                    setTotalPrice(totalPrice - issueData?.price);
                } else {
                    setTotalPrice(totalPrice - issueData?.foreign_price);
                }
                updatedOptions.splice(indexToRemove, 1);
            }
        }
        setSelectedOptions(updatedOptions);

    };


    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
        if (event.target.value !== "") {
            const targetTrainer = TrainerList.find(trainer => trainer.id === parseInt(event.target.value));
            setSelectedTrainer(targetTrainer);
            //console.log("trainer selected ------------------------>", TrainerList)
        }
    };

    const handleTrainerChange = (trainerData) => {

        setSelectedTrainer({})
        if (trainerData?.id !== "") {
            if (trainerData?.per_session_price !== 0) {
                setSelectedTrainer(trainerData)
            }
        }
    };

    const Payment = () => {
        if (1) {
            const form = new FormData();
            if (selectedValue !== "") {
                form.append("trainer_id", selectedValue)
            }
            form.append("price_id", curPrice?.id)
            form.append("currency", currency)
            form.append("platform", "website")
            form.append("prefered_time", selectedBatch)
            let all_health_issue = "";
            selectedOptions?.map((item, index) => {
                if (all_health_issue !== "") {
                    all_health_issue += ",";
                }
                all_health_issue += item?.id;
            })
            if (all_health_issue !== "") {
                form.append("health_issues", all_health_issue)
            }

            OrderModal.createOrder(form).then((res) => {
                // console.clear();
                // console.log("logggggg------------>", res.data)
                if (res?.data?.data?.paymentUrl) {
                    window.location.href = res?.data?.data?.paymentUrl;
                }
            }).catch((error) => {
                //console.log("error => ", error?.response?.data?.message);
                helper.sweetalert.toast(error?.response?.data?.message, "warning");
            })
        } else {
            helper.sweetalert.toast("Please Select Batch", "warning");
        }
    }

    const [disclaimer, setDisclaimer] = useState(false)

    console.log(disclaimer, "setDisclaimer")
    const toggleDisclaimer = () => {
        setDisclaimer(!disclaimer);
    };

    const handleSubmit = () => {
        helper.sweetalert.toast("Please Select disclaimer", "warning");
    }

    return (
        <>
            {packagess?.length > 0 &&
                <>
                    {packagess?.map((packageList, index) => {
                        if (packageList?.packages?.length > 0) {
                            return (
                                <>
                                    <PackageSectionCard packIndex={index} packageList={packageList} handlePackClick={handleShow} key={`packageCard${index}`} />
                                </>
                            );
                        }

                    })}
                </>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{ height: '0px', borderStyle: 'none', zIndex: "2000" }}>
                </Modal.Header>
                <Card>
                    <Card.Img closeButton variant="top" src={currentPackage?.image_url} height={250} style={{ position: 'relative', top: "-32px", borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />
                    <Card.Body>
                        <Card.Title style={{ marginTop: '-25px', marginLeft: '10px', color: 'black', fontWeight: 'bold' }}>{currentPackage?.name}</Card.Title>
                        <Card.Text style={{ padding: '10px', color: 'black' }}>
                            <span style={{ fontSize: '16px', fontWeight: '600', marginTop: '-20px', color: 'black' }}> {currentPackage?.short_description}</span>

                            <div style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: currentPackage?.long_description }} />
                            <div className="row">
                                {currentPackage?.package_prices?.map((pricesList, index) => {
                                    return (
                                        <PackageModelPriceCard packagePriceList={pricesList} handlePricePicked={handleCheckboxChange} key={`packprices${index}`} />
                                    );
                                })}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button onClick={handleClose} style={{ position: 'relative', borderRadius: '8px', borderStyle: 'none', paddingLeft: '20px', paddingRight: '20px' }}> Cancel</button>
                                <button className='primary-btn' disabled={!isButtonActive} onClick={handlePackage} style={{ position: 'relative', borderRadius: '8px', borderStyle: 'none' }}>Subscribe</button>

                            </div>
                            {showAlert && (
                                <div className="alert alert-danger" role="alert">
                                    Please Login To Continue
                                </div>
                            )}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Modal>
            <Modal p="4" show={showpakage} onHide={handleClosepackage} size="lg" className=''>
                <Modal.Header closeButton >
                    <Modal.Title style={{ fontSize: '30px' }}>Payment Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className={`prefered-time-div ${currentPackage?.pack_cat_id === 3 ? "d-none":""}`} >
                        <span className='mt-2' style={{ marginLeft: '8px' }}> Select Prefered Time</span>
                        <div className="card mb-4" style={{ height: '50px', border: 'none', marginTop: '8px' }}>
                            <select className="form-select" style={{ paddingLeft: '15px' }} required name='prefered_time' value={selectedBatch} onChange={handleBatchChange}>
                                <option value="">Select Batch Time</option>
                                {batchList && batchList.map((list, index) =>
                                    <>
                                        <option id={list?.id} value={list?.id} key={`trainerlist${index}`}>
                                            {list?.batch_name}
                                        </option>
                                    </>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className={`trainer-select-div ${showTrainer}`} >
                        <span className='mt-2' style={{ marginLeft: '8px' }}> Select Trainer</span>
                        <div className="card mb-4" style={{ height: '50px', border: 'none', marginTop: '8px ' }}>
                            <select style={{ height: '50px', overflowY: 'scroll', paddingLeft: '15px' }} onChange={handleDropdownChange} value={selectedValue}>
                                <option value="">Any Trainer</option>
                                {TrainerList && TrainerList.map((list, index) =>
                                    <>
                                        <option id={list?.id} value={list?.id} key={`trainerlist${index}`}>
                                            {list?.full_name} - {list?.trainer_category} ({list?.specialization})
                                        </option>
                                    </>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className={`${currentPackage?.pack_cat_id === 3 ? "":"d-none"}`}>
                        <span>Health Isssue</span>
                        <hr style={{ margonTop: '8px' }} />
                        <div style={{ height: '200px', overflowY: 'scroll', overflowX: 'hidden' }}>
                            {Healthissue && Healthissue.map((issue, index) =>
                                <div className="card mt-2" key={`healthissuelist${index}`}>
                                    <input type="checkbox" price={issue.price} id={issue.id} value={issue.id} name={issue.name} onChange={(e) => (onOptionSelected(e, issue))} style={{ position: 'absolute', top: "15px", left: '25px' }} />
                                    <span style={{ position: 'relative', left: '100px', top: '10px' }}>{issue.name}</span>
                                    <span style={{ position: 'relative', top: "10px", left: '20px' }}>
                                        {currency === "INR" ? "₹ " + issue.price : "$ " + issue.foreign_price}
                                    </span>
                                    <span style={{ position: 'relative', top: "-15px", left: '100px' }}>{issue.descp}</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <hr />
                    <span className='mt-5' style={{ marginLeft: '8px', color: "red" }}> Disclaimer</span>
                    <div className="card mt-2" style={{ height: '100px', overflowY: 'scroll', overflow: 'hidden' }}>

                        <input type="checkbox" style={{ position: "absolute", top: "35px", left: '25px' }} required onChange={toggleDisclaimer} />
                        <span style={{ position: 'relative', left: '100px', top: '10px' }}>
                            Individuals with cervical pain, knee pain, back pain, or any injury or joint pain are advised to

                        </span>
                        <span style={{ position: 'relative', left: '100px', top: '10px' }}>

                            refrain from participating in our fitness training programs. Prioritize your health and consult a

                        </span>
                        <span style={{ position: 'relative', left: '100px', top: '10px' }}>

                            healthcare professional before engaging in physical activities. Your well-being is our priority.
                        </span>

                    </div>

                    <hr />


                    <div>
                        <h6 className='mb-4'>Payment info</h6>
                        <span><b>Base Price</b> </span> <span style={{ position: 'relative', float: 'right' }}>
                            {currency === "INR" ?
                                (<>₹ {curPrice?.price} /-</>) :
                                (<>$ {curPrice?.foreign_price} /-</>)
                            }
                        </span>
                        <br />
                        {selectedOptions &&
                            selectedOptions.map((healthoption, index) => {

                                return (
                                    <>
                                        <span key={`selectedhealth${index}`}>{healthoption?.type} - <b>{healthoption?.name}</b></span> <span style={{ position: 'relative', float: 'right' }}>{currency === "INR" ? (<>₹ {healthoption?.price} /-</>) : (<>$ {healthoption?.foreign_price} /-</>)}</span><br />
                                    </>
                                );
                            })
                        }

                        {selectedTrainer?.per_session_price &&
                            <>
                                <span>Trainer - <b>{selectedTrainer?.full_name}</b></span> <span style={{ position: 'relative', float: 'right' }}>{currency === "INR" ? (<>₹ {selectedTrainer?.per_session_price} /-</>) : (<>$ {selectedTrainer?.per_session_foreign_price}</>)}</span><br />
                            </>
                        }

                        <hr />
                        {currency === "INR" ? (
                            <>
                                <span>Total Payable Amount </span> <span style={{ position: 'relative', float: 'right' }}><b>₹ {(selectedTrainer?.per_session_price ? totalPrice + selectedTrainer?.per_session_price : totalPrice).toFixed(2)} /-</b></span>
                            </>
                        ) : (
                            <>
                                <span>Total Payable Amount </span> <span style={{ position: 'relative', float: 'right' }}><b>$ {(selectedTrainer?.per_session_foreign_price ? totalPrice + selectedTrainer?.per_session_foreign_price : totalPrice).toFixed(2)} /-</b></span>
                            </>
                        )}

                    </div>

                    <br />


                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {/* <Button variant='outlined'  style={{ position: 'relative'}} >Back</Button> */}
                        <button onClick={() => setShowpackage(false)} style={{ position: 'relative', borderRadius: '8px', borderStyle: 'none', paddingLeft: '20px', paddingRight: '20px' }}> Back</button>
                        <button className='primary-btn' onClick={disclaimer ? Payment : handleSubmit} style={{ position: 'relative', float: 'right', borderRadius: '8px', border: 'none' }} >Pay</button>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PackageSection;