import axiosInstance from "../Lib/axiosInstance";


const Order = class {
    async createOrder(data) {
        return await axiosInstance.post("order", data)
    }
    async userorder(query=[]) {
        return await axiosInstance.get(`order?${new URLSearchParams(query)}`)
    }
    async sectionlist(data) {
        return await axiosInstance.get(`order/${data}/sessions`, data)
    }
    async userOrderSessionList(data) {
        return await axiosInstance.get(`order/${data}/sessions`, data)
    }
    async userPendingSessionList(data) {
        return await axiosInstance.get(`order/assigned_sessions`, data)
    }
    async userAllSessionList(data) {
        return await axiosInstance.get(`order/sessions`, data)
    }
    async trainerAllOrder(data) {
        return await axiosInstance.get(`order/trainer_orders`, data)
    }
    async userOrderPackageFileList(data) {
        return await axiosInstance.get(`order/${data}/files`)
    }

    async createUserInfo(data) {
        return await axiosInstance.post("order/add_user_info", data)
    }
    async getUserInfo(orderId) {
        return await axiosInstance.get("order/get_user_info/"+orderId)
    }
    async updateUserInfo(id, data) {
        return await axiosInstance.put("order/update_user_info/"+id, data)
    }
}

export default new Order