import React, { useEffect, useState } from 'react';
// import image from '../Component/image/image.jpg'
import { Link, useLocation  } from 'react-router-dom';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
  // CDBSidebarCTA,
} from 'cdbreact';
// import Presentation from '../../Aesets/images/presentation.jpg'

const SideBar = () => {
  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();

    // Redirect to the login page or perform any other desired action
  };
  const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem("user_data")));
  const [isToggled, setIsToggled] = useState(window.innerWidth > 720 ? false:true);

  useEffect(() => {
    const handleResize = () => {
      setIsToggled(true);
      if(window.innerWidth > 720) {
        setIsToggled(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [currentUrl, setCurrentUrl] = useState("");
  const location = useLocation();
  useEffect(() => {
    setCurrentUrl(location.pathname);
    console.log("url ------------------------- > ", location.pathname);
  }, [location.pathname])
  return (
    <>
      <div className='nav border-0-radius' style={{ minHeight:'92vh',height: "100%"}}>
        <CDBSidebar  style={{ borderRadius: '0', margin: '0', minHeight:'inherit' }} textColor="#333" backgroundColor="#f0f0f0" variant="permanent" toggled={isToggled}>
          <CDBSidebarHeader className="font-weight-bold" prefix={<i className="fa fa-bars " />}>AR Elite Fit</CDBSidebarHeader>
          <CDBSidebarContent>
            <CDBSidebarMenu>
              <Link to='/profile' 
              className={`dashMenu ${location.pathname === '/profile' ? 'dashMenuActive' : ''}`}><CDBSidebarMenuItem icon="fa fa-user">Profile</CDBSidebarMenuItem></Link>
              <Link to='/Orders' 
              className={`dashMenu ${location.pathname === '/Orders' ? 'dashMenuActive' : ''}`}>
                <CDBSidebarMenuItem icon="fas fa-clipboard">My Plans</CDBSidebarMenuItem></Link>
              {userData?.user_type !== "trainer" ? (
                <Link to='/Pending-sessions' 
                className={`dashMenu ${location.pathname === '/Pending-sessions' ? 'dashMenuActive' : ''}`}><CDBSidebarMenuItem icon="fas fa-clipboard">Pending Sessions</CDBSidebarMenuItem></Link>
              ) : (
                <Link to='/My-sessions' 
                className={`dashMenu ${location.pathname === '/My-sessions' ? 'dashMenuActive' : ''}`}><CDBSidebarMenuItem icon="fas fa-clipboard">My Sessions</CDBSidebarMenuItem></Link>
              )}
              {/* <Link to='/order/sessions'>  <CDBSidebarMenuItem icon="fa fa-user-circle"   iconType="solid" >
                Sessions
              </CDBSidebarMenuItem></Link> */}
              <Link to='/' className='dashMenu'><CDBSidebarMenuItem icon="fas fa-sign-out-alt" iconType="solid" onClick={logout}>
                Logout
              </CDBSidebarMenuItem></Link>
            </CDBSidebarMenu>
          </CDBSidebarContent>
          {/* <CDBSidebarCTA theme="overlay" image={image} text="Sign up Pro Here"></CDBSidebarCTA> */}

          <CDBSidebarFooter style={{ textAlign: 'center' }}>
            <div
              className="sidebar-btn-wrapper"
              style={{ padding: '20px 5px' }}
            >
              {/* EliteFit */}
            </div>
          </CDBSidebarFooter>
        </CDBSidebar>
      </div>
    </>
  );
};

export default SideBar;